import NewsModel from "@/Models/News/NewsModel";

const news: NewsModel[] = [
    new NewsModel(
        'ขรก.ตร.จิตรอาสา กก.ตชด.๔๔',
        '"เราทำความ ดี ด้วยหัวใจ"',
        `พ.ต.ท.ปกาสิฐ หนูอิ่ม รอง ผกก.ตชด.๔๔  นำข้าราชการตำรวจจิตอาสา กก.ตชด.๔๔ จำนวน ๑๐๐ นาย  
        จัดกิจกรรมจิตอาสา "เราทำความ ดี ด้วยหัวใจ" บำเพ็ญสาธารณประโยชน์ ทำความสะอาด ปรับปรุงภูมิทัศน์รอบคลังอาวุธ กก.ตชด.๔๔ 
        โดยการตัดหญ้า เก็บขยะ ทาสีเสารั้ว ในระหว่างปฏิบัติกิจกรรมได้ปฏิบัติตามมาตรการป้องกันโรคติดต่อของกรมควบคุมโรคอย่างเคร่งครัด `,
        'www.facebook.com/photo/?fbid=497919452453084&set=pcb.497921172452912',
        0, new Date(2023, 0, 11, 14, 7, 0, 0).toUTCString(),
        '/news/bpp44_royal_volunteer_cleaningday.jpg', [], []
    ),
    new NewsModel(
        'ตรวจเยี่ยมรร.ตชด.ไอร์บือแต',
        'รร.ตชด.บ้านไอร์บือแต ต.ช้างเผือก  อ.จะแนะ จ.นราธิวาส',
        `พล.ต.ต.สมกูล กาญจนอุดมการ ผบก.ตชด.ภาค๔ ,พ.ต.อ.กวินศักดิ์  พีรยศธนนนท์ รอง ผบก.ตชด.ภาค ๔ ,
        พ.ต.อ.มานิต นาโควงศ์ ผกก.ตชด.๔๔ ,พ.ต.ท.กรณ์ ไชยเสือ รอง ผกก.ตชด.๔๔ , พ.ต.ท.หญิง อภินันท์ ชูช่วย  
        รอง ผกก.อก.ตชด.ภาค๔ ,พ.ต.ท.รังษี ปุริสาร ผบ.ร้อย ตชด.๔๔๒ ,พ.ต.ท.อเนชา ตาวัน ผบ.ร้อย ตชด.๔๔๖,
        พ.ต.ท.วินัย สุจริตธุระการ นายตำรวจนิเทศ ,ร.ต.อ.หญิง บุศรา สุวรรณรัตน์ รอง สว.กก.ตชด.๔๔ พร้อมคณะ ครูใหญ่ และคณะครู รร.ตชด.บ้านไอร์บือแต สถานที่ รร.ตชด.บ้านไอร์บือแต ต.ช้างเผือก  อ.จะแนะ  จ.นราธิวาส`,
        'www.facebook.com/bpp442/posts/pfbid02SmXRYFGpvysVLLaAUzfXBK8vM8bL7n9mPShYV5v3PcjfYKPNJadgyTBuFiybGKwHl',
        0, new Date(2023, 0, 20, 14, 56, 0, 0).toUTCString(),
        '/news/bpp401402441_visit_bppschrool.jpg', [], []
    ),
    new NewsModel(
        '“ผลงานรอบ 3 เดือน ของสำนักงานตำรวจแห่งชาติ”',
        '1 ต.ค. - 31 ธ.ค. 65',
        `ขับเคลื่อนนโยบายรัฐบาล กวาดล้างอาชญากรรมอย่างเด็ดขาดและต่อเนื่อง สืบสวนปราบปรามจับกุม คดียาเสพติด คดีออนไลน์ การพนัน อาวุธปืน
         สถานบริการ เพิ่มความเข้มในการคัดกรองและจัดระเบียบคนต่างด้าวฯ แก้ไขปัญหาและช่วยเหลือประชาชนในพื้นที่`,
        'www.facebook.com/bpp442/posts/pfbid036qyLcbXLRJfEuUToT1TcYUjoS8pfNYKa293h4TMS4GTvurrhF4FKPK3LaYi9PSdjl',
        0, new Date(2023, 0, 21, 17, 59, 0, 0).toUTCString(),
        '/news/result_cycle3_police.jpg', [], []
    ),
    new NewsModel(
        'ผอ.โครงการส่วนพระองค์และคณะ ตรวจเยี่ยมพื้นที่เสด็จฯ',
        'รร.ตชด.บ้านไอร์บือแต ต.ช้างเผือก  อ.จะแนะ จ.นราธิวาส',
        `มื่อ ๐๓๐๙๐๐-๐๓๑๕๐๐ ก.พ.๖๖
        คุณอภิสิทธิ์ พึ่งพร ผู้อำนวยการโครงการส่วนพระองค์, คุณนรัญจ์ โกศลเวช หัวหน้าฝ่ายโครงการพระราชดำริพื้นที่ภาคใต้, คุณธีรวุธ ปัทมาศ หัวหน้าฝ่ายโครงการพระราชดำริพื้นที่เฉพาะ, 
        พล.ต.ต.สมกูล  กาญจนอุดมการ ผบก.ตชด.ภาค ๔, พ.ต.อ.กวินศักดิ์  พีรยศธนนนท์ รอง ผบก.ตชด.ภาค ๔, พ.ต.อ.มานิต นาโควงศ์ ผกก.ตชด.๔๔, พ.ต.ท.หญิง อภินันท์  ชูช่วย รอง ผกก.ฝอ.บก.ตชด.ภาค ๔,
        พ.ต.ท.รังษี ปุริสาร ผบ.ร้อย ตชด.๔๔๒, พ.ต.ท.อเนชา ตาวัน ผบ.ร้อย ตชด.๔๔๖ ได้ตรวจเยี่ยมและกำหนดพื้นที่จุดเสด็จฯ`,
        'www.facebook.com/bpp442/posts/pfbid0hu91E7DtjoDVjHA6LMN4m3pCUtKbY5Gu5T5ATvRQtjfq3BLuSkb23Hk7JUK5eTyJl',
        0, new Date(2023, 1, 3, 9, 0, 0, 0).toUTCString(),
        '/news/private_rk_visit_bppschrool.jpg', [], []
    ),
    new NewsModel(
        'ผอ.โครงการส่วนพระองค์และคณะ ตรวจเยี่ยมพื้นที่เสด็จฯ',
        'รร.ตชด.พีระยานุเคราะห์ฯ 4 ต.ปะโด อ.มายอ จ.ปัตตานี',
        `เมื่อ ๐๔๐๙๐๐-๐๔๑๕๐๐ ก.พ.๖๖
        คุณอภิสิทธิ์ พึ่งพร ผู้อำนวยการโครงการส่วนพระองค์, คุณนรัญจ์ โกศลเวช หัวหน้าฝ่ายโครงการพระราชดำริพื้นที่ภาคใต้, คุณธีรวุธ ปัทมาศ หัวหน้าฝ่ายโครงการพระราชดำริพื้นที่เฉพาะ,
        พล.ต.ต.สมกูล กาญจนอุดมการ ผบก.ตชด.ภาค ๔, พ.ต.อ.กวินศักดิ์ พีรยศธนนนท์ รอง ผบก.ตชด.ภาค ๔, พ.ต.อ.มานิต นาโควงศ์ ผกก.ตชด.๔๔, 
        พ.ต.ท.หญิง อภินันท์  ชูช่วย รอง ผกก.ฝอ.บก.ตชด.ภาค ๔ ,พ.ต.ท.รังษี ปุริสาร ผบ.ร้อย ตชด.๔๔๒ ,พ.ต.ต.วัชรพงษ์ ชิณวงศ์ ผบ.ร้อย ตชด.๔๔๔ ได้ตรวจเยี่ยมและกำหนดพื้นที่จุดเสด็จฯ `,
        'www.facebook.com/bpp442/posts/pfbid034uV8Kr9UCUt9Q4bCrigj6nnXgbSqBpau2REn287LyDwHrwKPFAAyBebREp9ykm5Al',
        0, new Date(2023, 1, 4, 18, 0, 0, 0).toUTCString(),
        '/news/private_rk_visit_bppschrool_2.jpg', [], []
    ),
    new NewsModel(
        'รอง ผบช.ตชด.พร้อมคณะตรวจเยี่ยม รร.ตชด.',
        'รร.ตชด.บ้านไอร์บือแต ต.ช้างเผือก  อ.จะแนะ จ.นราธิวาส',
        `เมื่อ ๐๗๑๐๐๐ ก.พ.๖๖
        พล.ต.ต.สุนทร เฉลิมเกียรติ รอง ผบช.ตชด. พร้อมคณะรวม ๕ นาย ได้เดินทางมาตรวจเยี่ยม รร.ตชด.บ้านไอร์บือแต พบ ผกก.ตชด.๔๔ ,ครูใหญ่และคณะครูให้การต้อนรับเพื่อติดตามความก้าวหน้าการเตรียมการรับเสด็จฯ ตามคำแนะนำของคณะ สสท. ที่ได้มาแนะนำเมื่อ ๓ ก.พ.๖๖ และได้ให้คำแนะนำเพิ่มเติมดังนี้
             ๑. การซักซ้อมการพูดของครูและนักเรียนจะต้องพูดให้ถูกต้องตามสคริปและจังหวะการพูดให้เหมาะสมไม่ช้าหรือไม่เร็วเกินไป ท่าทางให้เป็นธรรมชาติ
           ๒. ความสะอาดของสถานที่จุดรับเสด็จฯ และบริเวณโดยรอบต้องสะอาดเรียบร้อยสมพระเกียรติ
           ๓. การแต่งกายของครู นักเรียน และผู้ปกครอง ต้องสะอาดเรียบร้อยและสุภาพ
           ๔. คำแนะนำต่างๆ ที่คณะ สสท. ได้แนะนำไว้ให้ปรับแก้ไขให้ถูกต้องเหมาะสม และให้เป็นไปตามข้อมูลที่ได้ปรับแก้แล้ว ให้มีการซักซ้อมบ่อยๆเพื่อให้เกิดความชำนาญและแม่นยำ`,
        'www.facebook.com/bpp442/posts/pfbid0hGzWhCbzeTdPyJQj5faFjk7uEVfEJ8vzPvfaBeL7c8P6cpY3rsfdXFsGZUvUemThl',
        0, new Date(2023, 1, 8, 18, 0, 0, 0).toUTCString(),
        '/news/r_s_b_.jpg', [], []
    ),
    new NewsModel(
        'ผกก.ตชด.๔๔ สั่งการให้ ชป.มดแดง ฉก.ตชด.44(เดลต้า)',
        'บก.ฉก.ยะลา ต.เขาตูม อ.ยะรัง จ.ปัตตานี',
        `เมื่อ ๐๙๐๗๓๐ ก.พ.๖๖ พ.ต.อ.มานิต นาโควงศ์ ผกก.ตชด.๔๔ สั่งการให้ ชป.มดแดง ฉก.ตชด.44(เดลต้า) 
        นำโดย ร.ต.ต.วิเชียร จันทรัตน์ หน.ชุด พร้อมพวก ๘ นาย ปฏิบัติภารกิจร่วมพิธีปล่อยแถวและฟังคำชี้แจงการปฏิบัติ 
        ตามแผนปิดเมืองขั้นที่๒ โดยมี พล.ต.ธนัช ฉิมพาลี ผบ.ฉก.ยะลา เป็นประธานในการปล่อยแถวและชี้แจงการปฏิบัติตามแผนปิดเมืองขั้น๒ 
        เพื่อควบคุมพื้นที่ ลว.เส้นทาง ตรวจสอบบุคคล และยานพาหนะ แสดงกำลังจำกัดเสรีการปฏิบัติสนับสนุน วันสถาปนา อส. ในพื้นที่เขตเทศบาลนครยะลา`,
        'www.facebook.com/bpp442/posts/pfbid02qy7CruQ7cA4w4CLv9oLbJpJ7G6K8XMdGXq9aAbKWv7ZTQ11QpxbYqnDXCNghyN6tl',
        0, new Date(2023, 1, 9, 12, 0, 0, 0).toUTCString(),
        '/news/bpp44_opt_lr_0.jpg', [], []
    ),
    new NewsModel(
        'ศึกษาดูงานที่สถานีวิทยุโทรทัศน์กองทัพบกช่อง ๕',
        'สถานีวิทยุโทรทัศน์กองทัพบก (ททบ.) กรุงเทพมหานคร',
        `เมื่อ ๐๙๐๘๐๐ ก.พ.๖๖ ผู้เข้าอบรมศึกษาดูงานที่สถานีวิทยุโทรทัศน์กองทัพบกช่อง ๕ เพื่อได้ทราบถึงกระบวนการทำงาน 
        ของการนำเสนอข่าว ตั้งแต่การรับข่าวจากแหล่งข่าวจนถึงออกอากาศให้เรารับชมทางโทรทัศน์ หรือทางสื่อโซเชียล ซึ่งเป็นหนึ่งในกิจกรรมที่บรรจุในหลักสูตร 
        โครงการฝึกอบรมการบันทึกภาพ และการเขียนข่าวประชาสัมพันธ์ เพื่อพัฒนาศักยภาพบุคลากรด้านการประชาสัมพันธ์ ของ บช.ตชด. ประจำปีงบประมาณ พ.ศ.๒๕๖๖
        "COMBAT CAMERA"`,
        'www.facebook.com/bpp442/posts/pfbid0VDwXyLPDC3geTQtgboVBAhXhrAa949MzoEs9yC1VbdZ8z3AvufdZ7ovqBPwFjJuLl',
        0, new Date(2023, 1, 10, 12, 0, 0, 0).toUTCString(),
        '/news/bpp_tr_am.jpg', [], []
    ),
    new NewsModel(
        'ตรวจเยี่ยมพื้นที่รับเสด็จฯ',
        'รร.ตชด.บ้านไอร์บือแต ต.ช้างเผือก  อ.จะแนะ จ.นราธิวาส',
        `เมื่อ ๑๙๐๘๐๐-๑๙๑๔๐๐ ก.พ.๖๖ 
         พล.ต.ต.ยงเกียรติ มนปราณีต รอง ผบช.ตชด., พล.ต.ต.สมกูล กาญจนอุดมการ ผบก.ตชด.ภาค ๔, พ.ต.อ.มานิต นาโควงศ์ ผกก.ตชด.๔๔, พ.ต.อ.หญิง ณัชชา เขมะสิงคิ ผกก.ฝอ.๗ บช.ตชด.,
         พ.ต.ท.ธีรศักดิ์ โพธิ์ศรีมา รอง ผกก.ตชด.๔๔, พ.ต.ท.อเนชา ตาวัน ผบ.ร้อย ตชด.๔๔๖, พ.ต.ท.วินัย สุจริตธุระการ นายตำรวจนิเทศ, ร.ต.อ.ชัยพัฒน์ สมบูรณ์ดี ผบ.มว.กก.ตชด.๔๔, 
         ร.ต.อ.หญิง บุศรา สุวรรณรัตน์ รอง สว.กก.ตชด.๔๔ โดยมีคณะ ครูใหญ่ และคณะครู รร.ตชด.บ้านไอร์บือแต ร่วมให้การต้อนรับ "`,
        'www.facebook.com/bpp442/posts/pfbid08HdxvDt93fPrTEajLY2xy5M7QnKo2fXfm7CqmjZfxBg2Cevn5GBaxTRPxFZfjwe9l',
        0, new Date(2023, 1, 19, 18, 0, 0, 0).toUTCString(),
        '/news/v_rp_vpi_bppschrool.jpg', [], []
    ),
    new NewsModel(
        'รับเสด็จสมเด็จพระกนิษฐาธิราชเจ้า กรมสมเด็จพระเทพรัตนราชสุดาฯ สยามบรมราชกุมารี',
        'รร.ตชด.บ้านไอร์บือแต ต.ช้างเผือก อ.จะแนะ จ.นราธิวาส',
        `เมื่อ ๒๐๐๙๐๐ ก.พ.๖๖ 
        พล.ต.ท.ณัฐ สิงห์อุดม ผบช.ตชด., พล.ต.ต.สุนทร เฉลิมเกียรติ รอง ผบช.ตชด., พล.ต.ต.สมกูล กาญจนอุดมการ ผบก.ตชด.ภาค ๔,
        พ.ต.อ.มานิต นาโควงศ์ ผกก.ตชด.๔๔ พร้อมกำลังพล ร่วมรับเสด็จสมเด็จพระกนิษฐาธิราชเจ้า กรมสมเด็จพระเทพรัตนราชสุดาฯ สยามบรมราชกุมารี
        โดยมี นายสนั่น พงษ์อักษร ผวจ.นราธิวาส และหัวหน้าส่วนราชการที่เกี่ยวข้อง ร่วมรับเสด็จฯ ทรงติดตามการดำเนินงานโครงการพัฒนาเด็ก 
        และเยาวชนในถิ่นทุรกันดาร ผ่านสื่ออิเล็กทรอนิกส์ (ออนไลน์) `,
        'www.facebook.com/bpp442/posts/pfbid07PSGopQ4xq8w8ddSgGXjy7Qj6kcJR4WwexLWzS48zB826jdTCPu7jv8SsNy7dkyql',
        0, new Date(2023, 1, 21, 18, 0, 0, 0).toUTCString(),
        '/news/generalbpp_a_k_bppschrool.jpg', [], []
    ),
    new NewsModel(
        'รับเสด็จสมเด็จพระกนิษฐาธิราชเจ้า กรมสมเด็จพระเทพรัตนราชสุดาฯ สยามบรมราชกุมารี',
        'รร.ตชด.พีรยานุเคราะห์ฯ ๔ ต.ปะโด อ.มายอ จ.ปัตตานี',
        `เมื่อ ๒๐๑๒๓๐ ก.พ.๖๖ เมื่อวันที่ ๒๐ ก.พ.๖๖ พล.ต.ท.ณัฐ สิงห์อุดม ผบช.ตชด., พล.ต.ต.ยงเกียรติ มนปราณีต รอง ผบช.ตชด., 
        พล.ต.ต.สุนทร เฉลิมเกียรติ รอง ผบช.ตชด., พล.ต.ต.สมกูล กาญจนอุดมการ ผบก.ตชด.ภาค ๔, พ.ต.อ.มานิต นาโควงศ์ ผกก.ตชด.๔๔ พร้อมกำลังพล 
        ร่วมรับเสด็จสมเด็จพระกนิษฐาธิราชเจ้า กรมสมเด็จพระเทพรัตนราชสุดาฯ สยามบรมราชกุมารี โดยมี นางพาตีเมาะ สะดียามู ผวจ.ปัตตานี และหัวหน้าส่วนราชการที่เกี่ยวข้อง 
        ร่วมรับเสด็จฯ ทรงติดตามการดำเนินงานโครงการพัฒนาเด็กและเยาวชนในถิ่นทุรกันดาร ผ่านสื่ออิเล็กทรอนิกส์(ออนไลน์) `,
        'www.facebook.com/bpp442/posts/pfbid024VoDJGLkeMS467SZqCUdsEMNoWW83YyDVhdyHdWZVE76f6zisDdZHSSCUKSC1HPcl',
        0, new Date(2023, 1, 21, 18, 0, 0, 0).toUTCString(),
        '/news/generalbpp_a_k_bppschrool_2.jpg', [], []
    )
    ,
    new NewsModel(
        'พิธีมอบทุนการศึกษา จากมูลนิธิ มส.ตชด.สว.ประจำปีการ ๒๕๖๕',
        'ห้องประชุมยุทธการ กก.ตชด.๔๔',
        `มื่อ ๒๓๑๐๓๐ ก.พ.๖๖  
        พ.ต.อ.มานิต นาโควงศ์ ผกก.ตชด.๔๔ ประธานในพิธีมอบทุนการศึกษา จากมูลนิธิ มส.ตชด.สว.ประจำปีการ ๒๕๖๕ (ปีการศึกษา ๒๕๖๔) และมูลนิธิประกายสิทธิ์ โดยมีบุตร-ธิดา ของข้าราชการตำรวจในสังกัด ได้รับทุนการศึกษาดังนี้ 
        -ประเภทเรียนดีจำนวน ๕ ทุน 
        -ประเภทขาดแคลนจำนวน ๙ ทุน 
        -ทุนการศึกษาจากมูลนิธิพระพุทธศรีประกายสิทธิ์ จำนวน ๑ ทุน`,
        'www.facebook.com/bpp442/posts/pfbid0Grf3YSoGmg5ANZ8Z5sY3vsgVF5mh5ZA4bw5HtXSM8gUan1EfKpaKRNeQsVtKCrhcl',
        0, new Date(2023, 1, 23, 18, 0, 0, 0).toUTCString(),
        '/news/cmdbpp4401_v_s_a.jpg', [], []
    ),
    new NewsModel(
        'ต้อนรับชมรม "SALAM TAIPING BIKERS"',
        'ชฝต.๔๔๑๒ บ้านมูโนะ ม.๑ ต.มูโนะ อ.สุไหงโก-ลก จ.นราธิวาส',
        `เมื่อ ๒๔๑๒๐๐ ก.พ.๖๖
        พ.ต.ท.ธีระโชติ ปฐมวณิชกะ ผบ.ร้อย ตชด.๔๔๗/ผบ.ศฝด.๔๔๑ สย.๓,
        ร.ต.อ.อับดุลฮากีม เจ๊ะเม๊าะ รองผบ.ร้อย ตชด.๔๔๗/รอง ผบ.ศฝด.๔๔๑ สย.๓ 
        พร้อมด้วยชฝต.๔๔๑๑, ชฝต.๔๔๑๒ ร่วมต้อนรับชมรม "SALAM TAIPING BIKERS" ประเทศมาเลเซีย 
        และร่วมกันแจกของอุปโภคบริโภคให้กับชาวบ้านตำบล มูโนะ จำนวน ๑๒๐ ชุด`,
        'www.facebook.com/bpp442/posts/pfbid0228CLSnVXckR28M8y9C8fdRjEbUsBvuNjEksXUiXF4vqCYGaSsnnnXDp276iCAq8bl',
        0, new Date(2023, 1, 27, 9, 0, 0, 0).toUTCString(),
        '/news/bpp447_vo_4412.jpg', [], []
    ),
    new NewsModel(
        'กิจกรรมจิตอาสา "เราทำความ ดี ด้วยหัวใจ"',
        'วัดพุทธภูมิ พระอารามหลวง ต.สะเตง อ.เมือง จ.ยะลา',
        `เมื่อ ๐๓๐๙๐๐ มี.ค.๖๖ พ.ต.อ.มานิต นาโควงศ์ ผกก.ตชด.๔๔ มอบหมายให้ พ.ต.ท.ธีรศักดิ์ โพธิ์ศรีมา รอง ผกก.ตชด.๔๔, 
        ร.ต.อ.หญิง กรรณิการ์ เกลี้ยงสุก รอง สว.กก.ฯ รรท.สว.กก.ตชด.๔๔ (จิตอาสา ๙๐๔) นำกำลังพลจิตอาสา กก.ตชด.๔๔ 
        จัดกิจกรรมจิตอาสา "เราทำความ ดี ด้วยหัวใจ" พัฒนาวัดพุทธภูมิพระอารามหลวงเนื่องในวันมาฆบูชา เพื่อเป็นการบำเพ็ญสาธารณประโยชน์ 
        ทำนุบำรุงศาสนาสถาน โดยปรับปรุงภูมิทัศน์บริเวณรอบวัด กวาดใบไม้ เก็บขยะ และล้างห้องน้ำ เพื่อให้เกิดความเป็นระเบียบ เรียบร้อย สะอาด สวยงาม 
        ผู้เข้าร่วมกิจกรรมประกอบด้วย : 
        - ข้าราชการตำรวจจิตอาสา กก.ตชด.๔๔ จำนวน ๓๐ นาย `,
        'www.facebook.com/bpp442/posts/pfbid0Y73aioBAz41SKTDopSiRpV9u9hqHHF4x4sif7N9Jyb6E18cuqjFUARSPWasfYo1pl',
        0, new Date(2023, 2, 7, 9, 0, 0, 0).toUTCString(),
        '/news/cmdbpp44002_p_vol.jpg', [], []
    ),
    new NewsModel(
        'พิธีสวดมนต์ถวายพระพรแด่ สมเด็จพระเจ้าลูกเธอเจ้าฟ้าพัชรกิติยา',
        'หอประชุมอเนกประสงค์ กก.ตชด.๔๔',
        `เมื่อ ๐๘๐๘๔๕ มี.ค.๖๖ 
        พ.ต.อ.มานิต นาโควงศ์ ผกก.ตชด.๔๔ มอบหมายให้ ว่าที่ พ.ต.ต.ตระกูลพันธ์  สุทธิสวาท สว.กก.ตชด.๔๔ นายตำรวจเวรผู้ใหญ่ประจำวัน 
        เป็นประธานในพิธีสวดมนต์ถวายพระพรแด่ สมเด็จพระเจ้าลูกเธอเจ้าฟ้าพัชรกิติยาภา นเรนทิราเทพวดี กรมหลวงราชสาริณีสิริพัชร มหาวัชรราชธิดา 
        โดยมีข้าราชการตำรวจร่วมสวดมนต์ จำนวน ๑๓ นาย `,
        'www.facebook.com/bpp442/posts/pfbid02byMzQKnJ74isDZXcmrnutDjzBNKyiiaXpAHEzoW3md9oUqq9cMXzY5qhra6gNpKYl',
        0, new Date(2023, 2, 8, 18, 0, 0, 0).toUTCString(),
        '/news/bpp4401001_b_p.jpg', [], []
    ),
    new NewsModel(
        'พิธีสวดมนต์ถวายพระพรแด่ สมเด็จพระเจ้าลูกเธอเจ้าฟ้าพัชรกิติยา',
        'หอประชุมอเนกประสงค์ กก.ตชด.๔๔',
        `เมื่อ ๐๙๐๘๔๕ มี.ค.๖๖ 
        พ.ต.อ.มานิต นาโควงศ์ ผกก.ตชด.๔๔ มอบหมายให้ พ.ต.ท.บัญญัติ จันทร์ทอง สว.กก.ตชด.๔๔ นายตำรวจเวรผู้ใหญ่ประจำวัน 
        เป็นประธานในพิธีสวดมนต์ถวายพระพรแด่ สมเด็จพระเจ้าลูกเธอเจ้าฟ้าพัชรกิติยาภา นเรนทิราเทพวดี กรมหลวงราชสาริณีสิริพัชร มหาวัชรราชธิดา 
        โดยมีข้าราชการตำรวจร่วมสวดมนต์ จำนวน ๑๘ นาย`,
        'www.facebook.com/bpp442/posts/pfbid0FFBKxaZN3XRdLNbZdqvHGJpGVu64ngLf5rLb7MdWPsqTGLWF6YiZgVDZuqTroVxYl',
        0, new Date(2023, 2, 9, 18, 0, 0, 0).toUTCString(),
        '/news/bpp4403001_b_p.jpg', [], []
    ),
    new NewsModel(
        'ร่วมให้การต้อนรับรองประธานกรรมาธิการตำรวจและคณะ',
        'ชฝต.๔๔๑๒ บ้านมูโนะ ม.1 ต.มูโนะ อ.สุไหงโก-ลก จ.นราธิวาส',
        `เมื่อ ๑๓๑๔๐๐ มี.ค.๖๖
          พ.ต.อ.มานิต นาโควงศ์ผกก.ตชด.๔๔/ผบ.ศฝด.๔๔๑ พร้อมด้วย พ.ต.ท.ธีระโชติ ปฐมวณิชกะ ผบ.ร้อย ตชด.๔๔๗/ผบ.ศฝด.๔๔๑ สย.๓,ฃ
          ร.ต.อ.สังวรณ์ เดชเชียร หน.ชฝต.๔๔๑๒  พร้อมกำลังพล ร่วมให้การต้อนรับคุณจิตภัสร์ กฤดากร รองประธานกรรมาธิการตำรวจและคณะ 
          พร้อมด้วย พล.ต.ต.สุนทร เฉลิมเกียรติ รอง ผบช.ตชด, พล.ต.ต.สมกูล กาญจนอุดมการณ์ ผบก.ตชด.ภาค๔, พ.ต.อ.ปรีชา วังปรีชา รอง ผบก.ตชด.ภาค๔ 
          ที่ร่วมเดินทางมาตรวจเยี่ยม ชฝต.๔๔๑๒ โดยได้กำลังใจกำลังพลที่ปฏิบัติหน้าที่พื้นที่ ๓ จชต.พร้อมได้มอบสิ่งของเพื่อเป็นขวัญกำลังใจแก่กำลังพล`,
        'www.facebook.com/bpp442/posts/pfbid02fjtsSWV2efjss7Y88DAUh6edTXXeHvAD4hPSdHovpVXwWUuW759GixiTjDD1DHzFl',
        0, new Date(2023, 2, 14, 9, 0, 0, 0).toUTCString(),
        '/news/633791.jpg', [], []
    ),
    new NewsModel(
        'ร่วมให้การต้อนรับ พล.ท.ศานติ ศกุนตนาค มทภ.๔/ผอ.รมน.ภาค ๔',
        'กองร้อย ตชด.๔๔๔ ต.บาโลย อ.ยะหริ่ง จว.ปัตตานี',
        `เมื่อ ๒๙๑๓๓๐-๒๙๑๗๓๐ มี.ค.๖๖ 
        พ.ต.อ.มานิต นาโควงศ์ ผกก.ตชด.๔๔  มอบหมายให้ พ.ต.ท.ธีรศักดิ์ โพธิ์ศรีมา รอง ผกก.ตชด.๔๔ พร้อมด้วย พ.ต.ท.วีระศักดิ์ คงเพชร  ผบ.ร้อย ตชด.๔๔๔,
        พ.ต.ท.บัญญัติ จันทร์ทอง สว.ผงป.กก.ตชด.๔๔ ,ข้าราชการตำรวจ ร้อย ตชด.๔๔๔ ร่วมให้การต้อนรับ พล.ท.ศานติ ศกุนตนาค มทภ.๔ ๔ /ผอ.รมน.ภาค ๔  
        ซึ่งเดินทางมาปฎิบัติภารกิจในพื้นที่ จว.ปัตตานี `,
        'www.facebook.com/bpp442/posts/pfbid0e1efdcyCTzox2jrYCWbWyupCJGrRPRR4Ah1rjzdvNTw4oCBebSRB2MtgTVMSVDdEl',
        0, new Date(2023, 2, 29, 9, 0, 0, 0).toUTCString(),
        '/news/bpp44002_interview_bpp444.jpg', [], []
    )
    ,
    new NewsModel(
        'ทำบุญตักบาตร และเจริญพระพุทธมนต์ ถวายแด่สมเด็จพระกนิษฐาธิราชเจ้า กรมสมเด็จพระเทพรัตนราชสุดาฯ สยามบรมราชกุมารี',
        'ณ บริเวณหน้าพระบรมราชานุสาวรีย์พระมหาธรรมราชาที่ ๑ กก.ตชด.๔๔',
        `เมื่อ ๐๓๐๗๐๐-๐๓๐๙๓๐ เม.ย.๖๖
        พ.ต.อ.มานิต นาโควงศ์ ผกก.ตชด.๔๔, พ.ต.ท.ธีรศักดิ์ โพธิ์ศรีมา รอง ผกก.ตชด.๔๔ 
        พร้อมด้วย ขรก.ตร.ในสังกัดและแม่บ้าน ขรก.ตร. ร่วมพิธีพิธีทำบุญตักบาตร และเจริญพระพุทธมนต์ 
        ถวายแด่สมเด็จพระกนิษฐาธิราชเจ้า กรมสมเด็จพระเทพรัตนราชสุดาฯ สยามบรมราชกุมารี 
        เนื่องในวันคล้ายวันพระราชสมภพ สมเด็จพระกนิษฐาธิราชเจ้า กรมสมเด็จพระเทพรัตนราชสุดาฯ สยามบรมราชกุมารี  ๒ เมษายน ๒๕๖๖ 
        เวลา  ๐๗.๐๐ - ๐๗.๓๐ น. 
        ร่วมทำบุญตักบาตรข้าวสารอาหารแห้ง แด่พระภิกษุสงฆ์ จำนวน ๕ รูป
        ณ บริเวณหน้าพระบรมราชานุสาวรีย์พระมหาธรรมราชาที่ ๑
        เวลา ๐๙.๐๐ - ๐๙.๓๐ น. 
        ร่วมพิธีพุทธมนต์ เพื่อถวายพระพรชัยมงคล ณ หอประชุมอเนกประสงค์ กก.ตชด.๔๔`,
        'www.facebook.com/bpp442/posts/pfbid07LWcQc4TGuaexncZaxMtfYWkCrsD7r5VmPRry4PmonKAeia7rcneR1YMxLy2UWLLl',
        0, new Date(2023, 3, 4, 9, 0, 0, 0).toUTCString(),
        '/news/bpp4401_dc5_002541.jpg', [], []
    )
    ,
    new NewsModel(
        'พิธีเปิดหมู่บ้านนักกีฬา "ละอ่อนจ๊างศึก เกมส์"',
        'กองกำกับการ ๑ กองบังคับการฝึกพิเศษ (ค่ายพระรามหก)',
        `เมื่อ ๒๐๐๗๓๐ เม.ย.๖๖
        พิธีเปิดหมู่บ้านนักกีฬา 
        การแข่งขันกีฬานักเรียนโรงเรียนตำรวจตระเวนชายแดน ครั้งที่ ๙ "ละอ่อนจ๊างศึก เกมส์"
        ที่จะมีขึ้น ระหว่างวันที่ ๒๐-๒๓ เม.ย.๖๖
        โดยมี พล.ต.ท.ณัฐ สิงห์อุดม ผบช.ตชด. เป็นประธานในพิธี`,
        'www.facebook.com/bpp442/posts/pfbid0jncMkFvzxrZo22XD5uTfPfqXbwH6jsg4NmCes1uzoezoNdqwJ8ZnxqU47Wjp2C2dl',
        0, new Date(2023, 3, 20, 9, 0, 0, 0).toUTCString(),
        '/news/bpp001_o_raaon_g.jpg', [], []
    ),
    new NewsModel(
        'ผบช.ตชด. เดินทางมาเป็นประธานในพิธีรดน้ำศพ',
        'วัดพุทธภูมิ พระอารามหลวง',
        `เมื่อ ๒๑๑๓๐๐-๒๑๑๖๐๐ เม.ย.๖๖ 
        พล.ต.ท.ณัฐ สิงห์อุดม ผบช.ตชด. เดินทางมาเป็นประธานในพิธีรดน้ำศพ ส.ต.ต.นันทยศ ชูดวง ผบ.หมู่ กก.ตชด.๔๔ 
        ที่เสียชีวิตจากเหตุการณ์คนร้ายลอบวางระเบิด บริเวณสะพานตอออ (บ้านตอออ) ต.กายูคละ อ.แว้ง จว.นราธิวาส 
        โดยมี พล.ต.ต.สมกูล กาญจนอุดมการ ผบก.ตชด.ภาค ๔, พ.ต.อ.ปรีชา วังปรีชา รอง ผบก.ตชด.ภาค ๔, 
        พ.ต.อ.มานิต นาโควงศ์ ผกก.ตชด.๔๔, พ.ต.ท.กรณ์ ไชยเสือ รอง ผกก.ตชด.๔๔, 
        พ.ต.ท.ธีรศักดิ์  โพธิ์ศรีมา รอง ผกก.ตชด.๔๔  พร้อมข้าราชการตำรวจในสังกัด และผู้บังคับบัญชาหน่วยกำลังในพื้นที่ ร่วมให้การต้อนรับและร่วมพิธีฯ`,
        'www.facebook.com/bpp442/posts/pfbid02CqXH7nTHvVJZK5E7vYddgDeaRCEwiZo1H2ft5xHi4U53PzY9vfAqAvxgbVnGUB9hl',
        0, new Date(2023, 3, 21, 18, 0, 0, 0).toUTCString(),
        '/news/bpp001_p_s_01.jpg', [], []
    ),
    new NewsModel(
        'พิธีอ่านสาร พล.ต.ท.ณัฐ สิงห์อุดม ผู้บัญชาการตำรวจตระเวนชายแดน',
        'กก.ตชด.๔๔ (ค่ายพญาลิไท)',
        `เมื่อ ๐๓๐๘๓๐ พ.ค.๖๖  
        พ.ต.อ.มานิต นาโควงศ์ ผกก.ตชด.๔๔ เป็นประธานพิธีอ่านสาร พล.ต.ท.ณัฐ สิงห์อุดม ผู้บัญชาการตำรวจตระเวนชายแดน 
        เนื่องในวันคล้ายวันสถาปนากองบัญชาการตำรวจตระเวนชายแดน ครบรอบ ๗๐ ปี ในวันเสาร์ที่ ๖ พฤษภาคม ๒๕๖๖ 
        ณ ลานหน้าพระบรมราชานุสาวรีย์ มหาธรรมราชาที่ ๑ (พญาลิไท) 
        โดยมี พ.ต.ท.กรณ์ ไชยเสือ รอง ผกก.ตชด.๔๔/ผบ.ฉก.ตชด.๔๔, พ.ต.ท.ธีรศักดิ์ โพธิ์ศรีมา  รอง ผกก.ตชด.๔๔ 
        พร้อมข้าราชการตำรวจ กก.ตชด.๔๔ ร่วมพิธีโดยพร้อมเพรียงกัน`,
        'www.facebook.com/bpp442/posts/pfbid02kTPQST7k4Daa1c4Mrum2rWJLENP7XYhFxrYc6WWkFy3r1vzKJLHfknMEShNx3HZkl',
        0, new Date(2023, 4, 4, 9, 0, 0, 0).toUTCString(),
        '/news/bpp4401_bppday_66.jpg', [], []
    ),
    new NewsModel(
        'กิจกรรมจิตอาสาพัฒนาปรับปรุงภูมิทัศน์ภายในหน่วย',
        'กก.ตชด.๔๔ (ค่ายพญาลิไท)',
        `เมื่อ ๐๓๑๐๐๐ พ.ค.๖๖  
        พ.ต.อ.มานิต นาโควงศ์ ผกก.ตชด.๔๔, 
        พ.ต.ท.ธีรศักดิ์ โพธิ์ศรีมา รอง ผกก.ตชด.๔๔  พร้อมด้วยข้าราชการตำรวจจิตอาสาในสังกัด กก.ตชด.๔๔ 
        ร่วมกันจัดกิจกรรมจิตอาสาพัฒนาปรับปรุงภูมิทัศน์ภายในหน่วย ตัดแต่งกิ่งไม้, ตัดหญ้า, กวาดขยะทำความสะอาดโดยรอบบริเวณหน่วย 
        เพื่อให้มีสภาพแวดล้อมที่ดี น่าอยู่อาศัย ผู้เข้าร่วมกิจกรรมประกอบด้วย วิทยากรจิตอาสา ๙๐๔ จำนวน ๒ นาย,
        ข้าราชการตำรวจจิตอาสา กก.ตชด.๔๔ จำนวน ๖๘ นาย รวมทั้งสิ้น  ๗๐  นาย
        เวลา ๑๒.๐๐ น.ร่วมรับประทานอาหาร ขนมจีน ไข่ต้ม ผักสด เฉาก๊วยเบตง ซึ่งได้รับการสนับสนุนจาก พ.ต.อ.มานิต นาโควงศ์ ผกก.ตชด.๔๔ 
        เวลา ๑๖.๓๐ น. ร่วมกิจกรรมแข่งขันกีฬาฟุตบอลเชื่อมความสัมพันธ์`,
        'www.facebook.com/bpp442/posts/pfbid02tRZhuNo4kjCw3U9q58J4XsjWxY6SoupUex2bE8bKMX8cAoPKesByUgN9gXpNsKSHl',
        0, new Date(2023, 4, 4, 10, 0, 0, 0).toUTCString(),
        '/news/bpp44_vl_bppday_66.jpg', [], []
    )
    ,
    new NewsModel(
        '8 ข้อ อย่าหาทำ "ในวันเลือกตั้ง"',
        'โทษหนัก ทั้งจำและปรับ',
        ``,
        'www.facebook.com/bpp442/posts/pfbid02HjoosSfGJ7xc3vbsE41nntJJhbaDaUozetxuEkFpkB9cwMFjHsNkkhzk8igGNWsvl',
        0, new Date(2023, 4, 11, 12, 0, 0, 0).toUTCString(),
        '/news/S__19759225.jpg', [], []
    ),
    new NewsModel(
        'บันทึกเทปรายการโทรทัศน์รายการพิเศษถวายพระพร',
        'ห้องส่งสถานีวิทยุโทรทัศน์แห่งประเทศไทยจังหวัดยะลา เลขที่ ๑๒ ถนนวงเวียน ๒ อ.เมือง จว.ยะลา',
        `เมื่อ ๒๓๑๑๑๕ พ.ค.๖๖ 
        พ.ต.อ.มานิต  นาโควงศ์ ผกก.ตชด.๔๔, พ.ต.ท.ธีรศักดิ์  โพธิ์ศรีมา รอง ผกก.ตชด.๔๔, สว.กก.ตชด.๔๔ (ผงป., กบ.)
        ,ผบ.ร้อย.กก.ตชด.๔๔ (ร้อย ตชด.๔๔๑ - ๔๔๖) ร่วมบันทึกเทปรายการโทรทัศน์รายการพิเศษถวายพระพร เนื่องในวันเฉลิมพระชนมพรรษา 
        สมเด็จพระนางเจ้าสุทิดา พัชรสุธาพิมลลักษณ พระบรมราชินี ในวันที่ ๓ มิ.ย.๖๖`,
        'www.facebook.com/bpp442/posts/pfbid021U2C4z7ebiRo4hz6rCYacQsVeJuMbGQncQTeJ4T3vkPWegBPWTKbigh1dxLruovql',
        0, new Date(2023, 4, 24, 10, 0, 0, 0).toUTCString(),
        '/news/bpp44_a_i_d_66.jpg', [], []
    ),
    new NewsModel(
        'เข้าร่วมกิจกรรมเฉลิมพระเกียรติสมเด็จพระนางเจ้าฯ พระบรมราชินี',
        'อาคารหอประชุมเฉลิมพระเกียรติ ๘๐ พรรษา มหาวิทยาลัยราชภัฏยะลา อ.เมือง จว.ยะลา',
        `เมื่อ ๐๓๐๗๐๐ มิ.ย.๖๖  
         พ.ต.อ.มานิต นาโควงศ์ ผกก.ตชด.๔๔ เข้าร่วมกิจกรรมเฉลิมพระเกียรติสมเด็จพระนางเจ้าฯ พระบรมราชินี เนื่องในโอกาสวันเฉลิมพระชนมพรรษา 
         ๓ มิถุนายน ๒๕๖๖ ร่วมกับ ร.ต.ท.ราเชนทร์  บุญรอด รอง.สว.กก.ตชด.๔๔ และหัวหน้าส่วนราชการในพื้นที่ 
         โดยมี นายสุพจน์ รอดเรือง ณ หนองคาย ผู้ว่าราชการจังหวัดยะลา เป็นประธานในพิธี`,
        'www.facebook.com/bpp442/posts/pfbid028Txkv4hYnFa8sBiHCSzjVY12LdzFZLE1we7NDSCzq2YuRjrAB9iVhYveUYFGzYmWl',
        0, new Date(2023, 4, 24, 10, 0, 0, 0).toUTCString(),
        '/news/66_bpp44_cmd_1_kq10.jpg', [], []
    ),
    new NewsModel(
        'พีธีประดับยศข้าราชการตำรวจชั้นสัญญาบัตร',
        'ห้องประชุมยุทธการ กก.ตชด.๔๔ ต.บุดี อ.เมือง จว.ยะลา',
        `เมื่อ ๐๘๐๘๓๐ มิ.ย.๖๖ 
        พ.ต.อ.มานิต  นาโควงศ์  ผกก.ตชด.๔๔  เป็นประธานในพีธีประดับยศข้าราชการตำรวจชั้นสัญญาบัตร
        ตามคำสั่งกองบัญชาการตำรวจตระเวนชายแดน ที่ ๑๔๑/๒๕๖๖  ลงวันที่ ๓๑ มีนาคม ๒๕๖๖  จำนวน ๑๔ นาย`,
        'www.facebook.com/bpp442/posts/pfbid02amCFWsizndwaiG54qMGEXRYUVSypBAFS7Nhe466XYSWbMkyvxe6PaDmWTAEZUmqsl',
        0, new Date(2023, 5, 8, 12, 0, 0, 0).toUTCString(),
        '/news/bpp44_new_lit53.jpg', [], []
    ),
    new NewsModel(
        'ปรับปรุงภูมิทัศน์ค่ายพญาลิไท',
        'บริเวณด้านหลังคลังเก็บสิ่งของพระราชทาน',
        `เมื่อ ๒๓๐๙๐๐-๒๓๑๒๐๐  มิ.ย.๖๖ 
        พ.ต.อ.มานิต นาโควงศ์ ผกก.ตชด.๔๔ มอบหมายให้ ร.ต.อ.หญิง กรรณิการ์ เกลี้ยงสุก รอง สว.กก.ตชด.๔๔ (จอส.กร.) 
        พร้อมด้วยกำลังพลจิตอาสา กก.ตชด.๔๔ จำนวน ๓๖ นาย ร่วมพัฒนาปรับปรุงภูมิทัศน์ค่ายพญาลิไท โดยร่วมกันตัดหญ้า, ตัดแต่งกิ่งไม้ เพื่อความเป็นระเบียบเรียบร้อย
        และสะดวกในการสัญจร บริเวณด้านหลังคลังเก็บสิ่งของพระราชทาน ตลอดแนวรั้วกำแพงติดกับโรงเรียนเฉลิมพระเกียรติสมเด็จพระศรีนครินทร์ยะลา `,
        'www.facebook.com/bpp442/posts/pfbid0HDanQdstwj123aJW4pi7FttHX4ygZi2Ztn9KBYg1tXdR64152BeVCZ98HjmmKeqwl',
        0, new Date(2023, 5, 23, 12, 0, 0, 0).toUTCString(),
        '/news/bpp44_jsa_1025.jpg', [], []
    ),
    new NewsModel(
        'พิธีเปิดการฝึกอบรมโครงการฝึกยุทธวิธีตำรวจ',
        'สนามยิงปืน ค่ายพญาลิไท กก.ตชด.๔๔ ',
        `เมื่อ ๒๔๐๙๐๐-๒๔๑๗๐๐ มิ.ย.๖๖  
         พ.ต.อ.สมชาย ศรีสรยุทธ์ รองผู้บัญชาการ กกล.ตชด.จชต./รองผู้บังคับศูนย์เฝ้าตรวจชายแดนที่ ๔๔๑ ประธานในพิธีเปิดการฝึกอบรมโครงการฝึกยุทธวิธีตำรวจ และยุทธวิธีฝ่ายตรงข้าม 
         พร้อมด้วย พ.ต.ท.ธีรศักดิ์ โพธิ์ศรีมา รอง ผกก.ตชด.๔๔, พ.ต.ท.รณรัตน์ ออมสินสมบูรณ์ หน.ฝอ.กกล.ตชด.จชต., สว.ทุกแผนกงาน, ผบ.ร้อย กก.ตชด.๔๔ ร้อย ตชด.๔๔๑-๔๔๒, 
         จนท.หมวดแพทย์ กก.ตชด.๔๔ และข้าราชการตำรวจ กก.ตชด.๔๔ เข้าร่วมพิธีโดยมีผู้เข้ารับการฝึกอบรม จำนวน ๑๐๑ นาย 
         เพื่อเสริมทักษะความชำนาญในการใช้อาวุธยุุทโธปกรณ์ ให้สามารถปฏิบัติงานรักษาความปลอดภัยในชีวิต และทรัพย์สินของทางราชการและของประชาชนได้อย่างมีประสิทธิภาพ`,
        'www.facebook.com/bpp442/posts/pfbid0Kn7Fb2gHgtu4akbLgW2xwJmMNvnpjhyyY6tSoSS7WrbNBZ1S6HW6snL3BEb97vjRl',
        0, new Date(2023, 5, 24, 12, 0, 0, 0).toUTCString(),
        '/news/bpp4_tac_re_6625.jpg', [], []
    ),
    new NewsModel(
        ' ร่วมบันทึกเทปรายการพิเศษถวายพระพรชัยมงคล',
        'ห้องส่งสถานีวิทยุโทรทัศน์แห่งประเทศไทยจังหวัดยะลา',
        `เมื่อ ๐๓๑๑๑๕ ก.ค.๖๖ 
        พ.ต.อ.มานิต นาโควงศ์ ผกก.ตชด.๔๔, พ.ต.ท.กรณ์ ไชยเสือ รอง ผกก.ตชด.๔๔/ผบ.ฉก., พ.ต.ท.ธีรศักดิ์ โพธิ์ศรีมา รอง ผกก.ตชด.๔๔, 
        สว.กก.ตชด.๔๔ ,ผบ.ร้อย กก.ตชด.๔๔ (ร้อย ตชด.๔๔๑ - ๔๔๗) ร่วมบันทึกเทปรายการพิเศษถวายพระพรชัยมงคล 
        เนื่องในโอกาสวันเฉลิมพระชนมพรรษา พระบาทสมเด็จพระปรเมนรามาธิบดีศรีสินทรมหาวชิราลงกรณฯ พระวชิรเกล้าเจ้าอยู่หัว `,
        'www.facebook.com/bpp442/posts/pfbid02sCwRAbNjax1VNiFGLHQKPwdf6CTN7EwQbX3ntRqcBaGL6Q4thLd5GqhfgLbdz5Lel',
        0, new Date(2023, 6, 4, 12, 0, 0, 0).toUTCString(),
        '/news/bpp44_ta_k_a.jpg', [], []
    ),
    new NewsModel(
        'เรื่องน่ารู้กฎหมายตำรวจ',
        'สำนักงานตำรวจแห่งชาติ',
        `พ.ร.บ.ตำรวจแห่งชาติ พ.ศ.๒๕๖๖ เกี่ยวกับประกาศ ก.ต.ช. เรื่อง แต่งตั้งคณะอนุกรรมการนโยบายตำรวจแห่งชาติ ๔ ด้าน 
        🔹️ด้านยุทธศาสตร์และการมีส่วนร่วมของประชาชน 
        🔹️ด้านกฎหมาย 
        🔹️ด้านการพัฒนาองค์กร
        🔹️ด้านสื่อสารมวลชนและเทคโนโลยีสารสนเทศ `,
        'www.facebook.com/bpp442/posts/pfbid0NqaoM4v55TzGncJMWLgZ76WBhYxvNM9oh8y7nvaxDQ9h1EkoiRBxPbFhnnx7rRnJl',
        0, new Date(2023, 6, 8, 12, 0, 0, 0).toUTCString(),
        '/news/p_as_saa.jpg', [], []
    ),
    new NewsModel(
        'กิจกรรมจิตอาสาบริจาคโลหิตถวายเป็นพระราชกุศลเฉลิมพระเกียรติ',
        'หอประชุมเอนกประสงค์ กก.ตชด.๔๔ ',
        `เมื่อ ๑๑๐๙๐๐-๑๑๑๒๐๐ ก.ค.๖๖ 
        พ.ต.อ.มานิต นาโควงศ์ ผกก.ตชด.๔๔  มอบหมายให้ พ.ต.ท.ธีรศักดิ์ โพธิ์ศรีมา รอง ผกก.ตชด.๔๔  พร้อมด้วย ว่าที่ พ.ต.ต.หญิง ศรีไพร เพชรสีไหม ผบ.ร้อย กก.๕ บก.กฝ.ฯ รรท.สว.กก.ตชด.๔๔ (จอส.กร.) 
        ร่วมกับเหล่ากาชาด  จังหวัดยะลา และ จนท.โรงพยาบาลยะลา  จัดกิจกรรมจิตอาสาบริจาคโลหิตถวายเป็นพระราชกุศลเฉลิมพระเกียรติ  
        พระบาทสมเด็จพระปรเมนทรรามาธิบดี ศรีสินทรมหาวชิราลงกรณ พระวชิรเกล้าเจ้าอยู่หัว เนื่องในโอกาสเฉลิมพระชนมพรรษา ๒๘ กรกฎาคม ๒๕๖๖
        ทั้งนี้จะนำไปใช้เป็นโลหิตสำรอง สำหรับรักษาพยาบาลผู้ป่วยต่อไป โดยมีผู้เข้าร่วมบริจาคโลหิต ดังนี้
        ๑.วิทยากรจิตอาสา ๙๐๔ จำนวน ๒ นาย
        ๒.ข้าราชการตำรวจจิตอาสา กก.ตชด.๔๔ จำนวน ๖๒ นาย
        ๓.ข้าราชการตำรวจ กกล.ตชด.จชต. จำนวน ๕ นาย
        ๔.แม่บ้าน กก.ตชด.๔๔ จำนวน ๒ คน
        ผู้สามารถบริจาคโลหิตได้ ๔๓ นาย ปริมาณโลหิต ๑๙,๓๕๐ มิลลิลิตร`,
        'www.facebook.com/bpp442/posts/pfbid02t3DQ9m1EAF4MPckq8pCqMx18gPChBZXCgBh5Nt9x1zoFGhopuRcWWB9x9ZWts32xl',
        0, new Date(2023, 6, 12, 9, 0, 0, 0).toUTCString(),
        '/news/bpp44_jitarsa_blood_re_66.jpg', [], []
    ),
    new NewsModel(
        'ฝึกทบทวนการช่วยเหลือผู้ประสบภัยทางน้ำ',
        'อ่างเก็บน้ำ กก.ตชด.๔๔',
        `เมื่อ ๐๔๐๙๐๐ ส.ค.๖๖ 
        พ.ต.อ.มานิต นาโควงศ์ ผกก.ตชด.๔๔ ได้สั่งการให้เตรียมความพร้อมชุดเผชิญเหตุสาธารณภัยตามแผนการป้องกันและบรรเทาสาธารณภัยของหน่วย 
        โดยมี ด.ต.กิตติศักดิ์ จันทร์อินทร์ หน.ชุดปฎิบัติการเฉพาะกิจ(ซาไก) ทำหน้าที่ หน.ชุดช่วยเหลือผู้ประสบภัย 
        พร้อมจนท.ประจำชุด ได้ทำการฝึกทบทวนการช่วยเหลือผู้ประสบภัยทางน้ำในรูปแบบ Unit School ณ อ่างเก็บน้ำ กก.ตชด.๔๔ ต.บุดี อ.เมือง จว.ยะลา 
        โดยมีรายละเอียดประกอบด้วย
        ๑.แนะนำการตรวจเช็คอุปกรณ์ให้มีความพร้อมก่อนปฏิบัติงาน
        ๒.ชี้แจง วางแผนการปฏิบัติการค้นหาและช่วยเหลือผู้ประสบภัยทางน้ำ
        ๓. การดำน้ำระบบ SCUBA
        ๓.๑ การค้นหาแบบเดินทาง
        ๓.๒ การค้นหาแบบวงกลม
        ๓.๓ การค้นหาแบบคลี่พัด
         เพื่อเป็นการเตรียมความพร้อมตามขั้นการป้องกันและลดผลกระทบ(ลดความเสี่ยง) และ ขั้นเตรียมความพร้อม(ก่อนเกิดภัย) ได้อย่างมีประสิทธิภาพและเต็มขีดความสามารถของหน่วยต่อไป`,
        'www.facebook.com/bpp442/posts/pfbid0b1XyCX7HeEA4q7B4u7ZG9Jjsi7kzvzfYUQHBfnLjyJ3WhgiueJWs7C9bxM4Uk14Kl',
        0, new Date(2023, 7, 4, 9, 0, 0, 0).toUTCString(),
        '/news/lrsu_re_water_rescure.jpg', [], []
    ),
    new NewsModel(
        'โครงการพัฒนาศักยภาพครูปฐมวัย รร.ตชด. ในสังกัด กก.ตชด.๔๔',
        'ห้องประชุมเซียน ตาน ฮวา ชั้น ๓ อาคารสังคมศาสตร์ มหาวิทยาลัยราชภัฏยะลา',
        `เมื่อ ๐๕๐๙๐๐ ส.ค.๖๖ 
        พ.ต.อ.มานิต นาโควงศ์ ผกก.ตชด.๔๔ มอบหมายให้ พ.ต.ท.ธีรศักดิ์ โพธิ์ศรีมา รองผกก.ตชด.๔๔, 
        พ.ต.ท.อเนชา ตาวัน ผบ.ร้อย กก.ตชด.๔๔ ร้อย ตชด.๔๔๖, พ.ต.ท.ปิยะ บุญเรือง ผบ.ร้อย กก.ตชด.๔๔ ร้อย ตชด.๔๔๒ 
        ร่วมพิธีเปิดโครงการพัฒนาศักยภาพครูปฐมวัย รร.ตชด. ในสังกัด กก.ตชด.๔๔`,
        'www.facebook.com/bpp442/posts/pfbid0ZffhG1yyiVxU8gwn1ungZk92jMp5BZnCFsxT2c1ZDbzzkCSGVgUbaFnNG5nJNeVol',
        0, new Date(2023, 7, 5, 9, 0, 0, 0).toUTCString(),
        '/news/bpp4402_op_dev_teach.jpg', [], []
    ),
    new NewsModel(
        'กิจกรรมจิตอาสาพัฒนาปรับปรุงภูมิทัศน์',
        'กก.ตชด.๔๔ ต.บุดี อ.เมือง จว.ยะลา',
        `เมื่อ ๐๘๐๙๐๐ ส.ค.๖๖ 
        พ.ต.อ.มานิต นาโควงศ์ ผกก.ตชด.๔๔ มอบหมายให้ ร.ต.อ.หญิง กรรณิการ์ เกลี้ยงสุก รอง สว.กก.ตชด.๔๔ (จอส.กร.)
        และกำลังพลจิตอาสา กก.ตชด.๔๔ รวม ๒๓ นาย  ร่วมกิจกรรมจิตอาสาพัฒนาปรับปรุงภูมิทัศน์ ตัดแต่งกิ่งไม้ ถางหญ้าตามแนวกำแพง 
        เก็บขยะ ทำความสะอาดบริเวณจุดทิ้งขยะในค่ายพญาลิไท ให้มีความสะอาดเรียบร้อย`,
        'www.facebook.com/bpp442/posts/pfbid02xm8RtWucjZZAqwspWsnL2X7NEY8aVE6UemWjMEipVHZzuLZpSjmzMTuGtuiCgaSql',
        0, new Date(2023, 7, 8, 9, 0, 0, 0).toUTCString(),
        '/news/bpp44_jitarsa_trashcleaning_66.jpg', [], []
    ),
    new NewsModel(
        'พิธีเปิดการแข่งขันกีฬาภายใน ประจำปี ๒๕๖๖ (พญาลิไทเกมส์  ๒๐๒๓)',
        'สนามกีฬาค่ายพญาลิไท กก.ตชด.๔๔  ต.บุดี  อ.เมือง  จว.ยะลา',
        `เมื่อ ๐๙๑๓๐๐ ส.ค.๖๖  
        พ.ต.อ.มานิต นาโควงศ์ ผกก.ตชด.๔๔ เป็นประธานในพิธีเปิดการแข่งขันกีฬาภายในประจำปี ๒๕๖๖(พญาลิไทเกมส์  ๒๐๒๓) 
        สร้างสัมพันธ์ สร้างพลัง สามัคคี  มีวินัย โดยมี พ.ต.ท.กรณ์  ไชยเสือ รอง ผกก.ตชด.๔๔/ผบ.ฉก.ตชด.๔๔, 
        พ.ต.ท.ธีรศักดิ์  โพธิ์ศรีมา รอง ผกก.ตชด.๔๔, สว.ทุกแผนกงาน, ผบ.ร้อย กก.ตชด ๔๔ ร้อย ตชด.ที่ ๔๔๑ - ๔๔๙,  
        ข้าราชการตำรวจในสังกัด และคณะแม่บ้านตำรวจ กก.ตชด.๔๔ เข้าร่วมพิธี`,
        'www.facebook.com/bpp442/posts/pfbid0xV5PXBW1Y9tkdxwGh63e5m8n2JzjfG3a4TtwuewjySdAmwqCAtdX9WAm6yHiccq2l',
        0, new Date(2023, 7, 9, 13, 0, 0, 0).toUTCString(),
        '/news/66_bpp44_sport.jpg', [], []
    ),
    new NewsModel(
        'ฝึกทบทวนการสู้รบทางยุทธวิธี',
        'กก.ตชด.๔๔ ต.บุดี อ.เมือง จว.ยะลา',
        `เมื่อ ๑๑๑๓๐๐-๑๑๑๖๓๐ ส.ค.๖๖ 
        พ.ต.อ.มานิต นาโควงศ์ ผกก.ตชด.๔๔ ได้สั่งการให้ฝึกทบทวนประจำสัปดาห์ ชุดปฎิบัติการเฉพาะกิจ(ซาไก) โดยมี ด.ต.กิตติศักดิ์  จันทร์อินทร์ รอง หน.ชุดปฎิบัติการเฉพาะกิจ(ซาไก) ทำหน้าที่ ครูฝึก พร้อม จนท.ประจำชุด ได้ทำการฝึกทบทวนการสู้รบทางยุทธวิธี ในรูปแบบ Unit School ณ กก.ตชด.๔๔ ต.บุดี อ.เมือง จว.ยะลา โดยมีรายละเอียดประกอบด้วย
        ๑.ทบทวนการเคลื่อนที่ประกอบโล่กันกระสุน
        ๒.ทบทวนการเข้าเคลียร์สิ่งปลูกสร้างแบบบ้านชั้นเดี่ยว,บ้านสองชั้นและอาคารแฟลต
        ๓.การนำพาผู้ได้รับบาดเจ็บในพื้นที่สู้รบแบบเร่งด่วน
        เพื่อเป็นการเตรียมความพร้อมตาม ขั้นการป้องกันและลดผลกระทบ(ลดความเสี่ยง) และ ขั้นเตรียมความพร้อม(ก่อนเกิดเหตุการณ์) ได้อย่างมีประสิทธิภาพ และเต็มขีดความสามารถของหน่วยต่อไป`,
        'www.facebook.com/bpp442/posts/pfbid02rMGtxxdXEJHzc4do7izKED41DN2bcPZjxtR6K6ARTKokZEKumiyA4ixecvLT4sWal',
        0, new Date(2023, 7, 11, 13, 0, 0, 0).toUTCString(),
        '/news/sakai_unitschrool_0866.jpg', [], []
    ),
    new NewsModel(
        'พิธีถวายเครื่องราชสักการะและวางพานพุ่ม',
        'อาคารหอประชุมเฉลิมพระเกียรติ ๘๐ พรรษา มหาวิทยาลัยราชภัฏยะลา อ.เมือง จว.ยะลา',
        `เมื่อ ๑๒๑๘๑๐-๑๒๑๙๓๐ ส.ค.๖๖
        พ.ต.อ.มานิต นาโควงศ์ ผกก.ตชด.๔๔  มอบหมายให้ พ.ต.ท.ธีรศักดิ์ โพธิ์ศรีมา รอง ผกก.ตชด.๔๔ พร้อมด้วยข้าราชการตำรวจชั้นสัญญาบัตร กก.ตชด.๔๔ 
        ร่วมกับส่วนราชการในพื้นที่ ร่วมพิธีถวายเครื่องราชสักการะและวางพานพุ่ม และพิธีจุดเทียนถวายพระพรชัยมงคล สมเด็จพระนางเจ้าสิริกิติ์ พระบรมราชินีนาถ พระบรมราชชนนีพันปีหลวง 
        เนื่องในโอกาสมหามงคลเฉลิมพระชนมพรรษา ๙๑ พรรษา ๑๒ สิงหาคม ๒๕๖๖ โดยมี นายสุพจน์  รอดเรือง ณ หนองคาย ผู้ว่าราชการจังหวัดยะลาเป็นประธานในพิธี`,
        'www.facebook.com/bpp442/posts/pfbid094qu1TKtKRLyeQAdkfhR6mNgLFjN4hSkTWYywJ1QVoJAxhX9BxJ8ab8mGP7dUNF6l',
        0, new Date(2023, 7, 12, 20, 0, 0, 0).toUTCString(),
        '/news/bpp4402_12q_66.jpg', [], []
    ), 
    new NewsModel(
        'ตรวจเยี่ยม รร.ตชด.บ้านปาโจแมเราะ',
        'อ.ธารโต จว.ยะลา',
        `เมื่อ ๑๕๑๑๓๐ ส.ค.๖๖
        พ.ต.อ.มานิต นาโควงศ์ ผกก.ตชด.๔๔ มอบหมายให้ พ.ต.ท.ธีรศักดิ์ โพธิ์ศรีมา รอง ผกก.ตชด.๔๔ เดินทางมาตรวจเยี่ยม รร.ตชด.บ้านปาโจแมเราะ ต.แม่หวาด อ.ธารโต จว.ยะลา 
        และ ชป.รปภ.รร.ตชด.ฯ โดยมี พ.ต.ท.อาดัม ขุนจันทร์ ครูใหญ่ รร.ตชด.บ้านปาโจแมเราะ พร้อมด้วย ด.ต.คัมภีร์ รักสุข หน.ชป.รปภ.รร.ฯ , ขตร., 
        คณะครูและนักเรียนร่วมให้การต้อนรับ โดยได้ตรวจสอบพื้นที่ที่ได้รับผลกระทบ และได้รับความเสียหายจาก พายุฝน และน้ำป่าไหลหลาก 
        เพื่อให้การสนับสนุนช่วยเหลือด้านงบประมาณ  การพัฒนา ปรับปรุง ซ่อมแซม รวมถึงหาแนวทางในการแก้ไขปัญหาต่อไป`,
        'www.facebook.com/bpp442/posts/pfbid0eA98D9G5Ju8A85j8qSf9PKtyrpot4ZpndGvGZpUFv62Ntev93cYCAVZhMoseHY1yl',
        0, new Date(2023, 7, 15, 12, 0, 0, 0).toUTCString(),
        '/news/bpp4402_vis_pajo_66.jpg', [], []
    ),
    new NewsModel(
        'ทดสอบสมรรถภาพร่างกายข้าราชการตำรวจ ครั้งที่ ๒ ประจำปี ๒๕๖๖',
        'กก.ตชด.๔๔ ต.บุดี อ.เมือง จว.ยะลา',
        `พ.ต.อ.มานิต นาโควงศ์ ผกก.ตชด.๔๔  มอบหมายให้ พ.ต.ท.ธีรศักดิ์ โพธิ์ศรีมา รอง ผกก.ตชด.๔๔  นำข้าราชการตำรวจ กก.ตชด.๔๔ ฝ่ายอำนวยการ, กองร้อย ตชด.๔๔๑ - ๔๔๒  เพื่อทดสอบสมรรถภาพร่างกายข้าราชการตำรวจ ครั้งที่ ๒ ประจำปี ๒๕๖๖`,
        'www.facebook.com/bpp442/posts/pfbid0oJ2d1isfbjtRzMx2wr7uLHpuZb4ccUu7Rd9xeVgjTER7mHghfLRPu4DhJPyUVyvTl',
        0, new Date(2023, 7, 17, 12, 0, 0, 0).toUTCString(),
        '/news/bpp44_test_pt_66.jpg', [], []
    ),
    new NewsModel(
        'อบรมเชิงปฏิบัติการสืบสวนหลังเหตุระเบิดองค์ความรู้ทางวิทยาศาสตร์เเละลายนิ้วมือเเฝง',
        'ห้องประชุมโรงเเรมชี เเซนด์ ซันหัวหิน รีสอร์ท ต./อ.ชะอำ จว.เพชรบุรี',
        `เมื่อ ๐๔๐๙๐๐- ๐๔๑๖๓๐ ก.ย.๖๖ 
        พ.ต.อ.มานิต นาโควงศ์ สั่งการให้ จ.ส.ต.ภานุวัฒน์ ชมภูนุช เข้าร่วมพิธีเปิดการฝึกอบรมเชิงปฏิบัติการสืบสวนหลังเหตุระเบิดองค์ความรู้ทางวิทยาศาสตร์เเละลายนิ้วมือเเฝง 
        จัดโดยสำนักงานผู้ช่วยทูตด้านกฏหมาย FBI ประเทศสหรัฐฯประจำประเทศไทยโดยมี พล.ต.ท.ดิเรท ธนานนท์นิวาส ที่ปรึกษา(สบ๘) สพฐ.ตร.เป็นประธานในพิธี`,
        'www.facebook.com/bpp442/posts/pfbid0tX6cB1Fmp5TFHwMvFsTs12vfb3vuTUWmJTPNAoHeMPw4GmaPECVL6KuP8ZrRN5pvl',
        0, new Date(2023, 8, 4, 18, 0, 0, 0).toUTCString(),
        '/news/inter_eod_fbi_66.jpg', [], []
    ),
    new NewsModel(
        'คณะอนุกรรมการป้องกันและปราบปรามการตัดไม้ทำลายป่าระดับจังหวัด (คปป.จังหวัด)',
        'ห้องประชุม ศรีนครา ศาลากลาง จว.ปัตตานี',
        `เมื่อ ๐๕๐๙๐๐ ก.ย.๖๖  
        พ.ต.อ.มานิต นาโควงศ์ ผกก.ตชด.๔๔ มอบหมายให้ พ.ต.ท.ธีรศักดิ์ โพธิ์ศรีมา รอง ผกก.ตชด.๔๔ เป็นผู้แทนร่วมประชุมคณะอนุกรรมการป้องกันและปราบปรามการตัดไม้ทำลายป่าระดับจังหวัด (คปป.จังหวัด) 
        โดยมี นายณัฐกฤช สิทธิโอสถ รอง ผวจ.ปัตตานี เป็นประธานในการประชุม `,
        'www.facebook.com/bpp442/posts/pfbid02L3sqcyhwaKbdg4xRSQa85bgdCJRsMRNASsQTW1jH5PPeU3ZZ9SZn9cQyLxSH4VRtl',
        0, new Date(2023, 8, 5, 18, 0, 0, 0).toUTCString(),
        '/news/kpp_yala_66.jpg', [], []
    ),
    new NewsModel(
        'ตรวจพื้นที่เตรียมการจัดตั้ง กอ.ปภ.๙๙ ตชด.',
        'อ.เมือง จว.นราธิวาส',
        `เมื่อ ๑๐๑๑๓๐ ก.ย.๖๖ พ.ต.อ.มานิต นาโควงศ์ ผกก.ตชด.๔๔ มอบหมายให้ พ.ต.ท.ธีรศักดิ์ โพธิ์ศรีมา รอง ผกก.ตชด.๔๔ 
        พร้อมด้วย พ.ต.ท.อเนชา ตาวัน ผบ.ร้อย กก.ตชด.๔๔ ร้อย ตชด.๔๔๖ และ ขตร.ร้อย ตชด.๔๔๗ 
        ร่วมตรวจพื้นที่เตรียมการจัดตั้ง กอ.ปภ.๙๙ ตชด.เพื่อถวายความปลอดภัยแด่สมเด็จพระกนิษฐาธิราชเจ้า กรมสมเด็จพระเทพรัตนราชสุดาฯสยามบรมราชกุมารี 
        มีกำหนดเสด็จทรงปฏิบัติพระราชกรณียกิจในพื้นที่ จว.ยะลา จว.นราธิวาส และ จว.ปัตตานี  ห้วงระหว่างวันที่ ๑๘-๒๒ ก.ย.๖๖`,
        'www.facebook.com/bpp442/posts/pfbid0KQrrgB1GtRxUgZwHEde2aJogDDPR5oa8kTNdpPnh84R6d1uwrvrkzHVz7mcMCtMTl',
        0, new Date(2023, 8, 10, 18, 0, 0, 0).toUTCString(),
        '/news/66_ch_tb_bpp4402.jpg', [], []
    )
    ,
    new NewsModel(
        'ตรวจเยี่ยม กพ. มว.รปภ.กก.ตชด.๔๔ ',
        'มว.รักษาความปลอดภัย กก.ตชด.๔๔ ต.บุดี อ.เมือง จว.ยะลา',
        `เมื่อ ๑๑๑๑๐๐ ก.ย.๖๖ 
        พ.ต.อ.มานิต นาโควงศ์ ผกก.ตชด.๔๔ มอบหมายให้ พ.ต.ท.ธีรศักดิ์ โพธิ์ศรีมา รอง ผกก.ตชด.๔๔, พ.ต.ต.ตระกูลพันธ์ สุทธิสวาท สว.กก.ตชด.๔๔ 
        ตรวจเยี่ยม กพ. มว.รปภ.กก.ตชด.๔๔ โดยมี ร.ต.อ.ชยพล บุญรัตนัง ผบ.มว.กก.ตชด.๔๔ พร้อมกำลังพลร่วมให้การต้อนรับ 
        โดยได้กำชับให้ ไม่ประมาทต่อสถานการณ์ พร้อมให้คำแนะนำในการปฏิบัติหน้าที่ รวมถึงสอบถาม ปัญหา ข้อขัดข้องในการปฏิบัติ `,
        'www.facebook.com/bpp442/posts/pfbid0f4uQLb3WJD1wjo5DFFx9sEs1LYCyLvZx9eXmNNK6J1QER5qSLapcctGA5agBf7Wtl',
        0, new Date(2023, 8, 11, 12, 0, 0, 0).toUTCString(),
        '/news/66_bpp4402_ch_gbpp44.jpg', [], []
    ),
    new NewsModel(
        'รอง ผบช.ตชด.และคณะ ตรวจเยี่ยม กก.ตชด.๔๔',
        'กก.ตชด.๔๔ ต.บุดี อ.เมือง จว.ยะลา',
        `เมื่อ ๑๕๑๓๔๕พ.ย.๖๖  
        พล.ต.ต.รุ่งโรจน์  ฐากูรปุณยสิริ รอง ผบช.ตชด.และคณะเดินทางมาตรวจเยี่ยมให้โอวาท
        มอบสิ่งของบำรุงขวัญให้กับข้าราชการตำรวจ กก.ตชด.๔๔ และประชุมรับฟังบรรยายสรุปผลการปฏิบัติงานที่สำคัญของหน่วย
        โดยมี พ.ต.อ.สมชาย ศรีศรยุทธ์ รอง ผบก.ตชด.ภาค ๔/รอง ผบ.ฉก.ตชด.จชต., พ.ต.อ.มานิต นาโควงศ์ ผกก.ตชด.๔๔, สว.กก.ตชด.๔๔, ผบ.ร้อยฯ และข้าราชการตำรวจ กก.ตชด.๔๔ ให้การต้อนรับ`,
        'www.facebook.com/bpp442/posts/pfbid02pGKYAUissGwyfApmpp799AVu7scwNxPaCwpgxatSZzpgHL5hQKK4EW4fdQG5ZZmEl',
        0, new Date(2023, 10, 15, 18, 0, 0, 0).toUTCString(),
        '/news/bppcmd02_vis44.jpg', [], []
    ),
    new NewsModel(
        'รอง ผบช.ตชด.และคณะ ตรวจเยี่ยมศกร.บ้านภักดี',
        'ศกร.ตชด.บ้านภักดี ต.เขื่อนบางลาง อ.บันนังสตา จว.ยะลา',
        `เมื่อ ๑๕๐๙๐๐ พ.ย.๖๖ 
        พล.ต.ต.รุ่งโรจน์ ฐากูรปุณยสิริ รอง ผบช.ตชด., พ.ต.อ.สมชาย ศรีสรยุทธ์ รอง ผบก.ตชด.ภาค ๔, พ.ต.อ.รังสรรค์ เนตรเกื้อกิจ รอง ผบก.สอ.บช.ตชด., 
        พ.ต.อ.มานิต นาโควงศ์ ผกก.ตชด.๔๔ และคณะ เดินทางมาตรวจเยี่ยม ศกร.ตชด.บ้านภักดี และให้โอวาทมอบสิ่งของบำรุงขวัญให้กับครูและนักเรียน ศกร.ตชด.บ้านภักดี 
        โดยมี พ.ต.ท.วินัย สุจริตธุระการ ครู (สบ ๒), ร.ต.ท.หญิง ประพิน มะลิชู ครูใหญ่ ศกร.ตชด.บ้านภักดี, ด.ต.คัมภีร์ รักสุข หน.ชป.รปภ.ตชด.บ้านภักดี และคณะครูให้การต้อนรับ`,
        'www.facebook.com/bpp442/posts/pfbid0QzhhVty6fnbdJjERvKhtY19YqQT1zXUgyVEjGS7eggRAWoUFVYXg6fmSB5PC2gBgl',
        0, new Date(2023, 10, 15, 18, 0, 0, 0).toUTCString(),
        '/news/bppcmd02_eduschool_pakdee.jpg', [], []
    ),
    new NewsModel(
        'ผกก.ตชด.๔๔ มอบอินทผาลัมแก่ข้าราชการตำรวจมุสลิม',
        'กก.ตชด.๔๔ ต.บุดี อ.เมือง จว.ยะลา',
        `เมื่อ ๑๒๐๘๓๐มี.ค.๖๗ พ.ต.อ.มานิต นาโควงศ์ ผกก.ตชด.๔๔ เป็นประธานในพิธีมอบอินทผาลัมแก่ข้าราชการตำรวจมุสลิม 
        เนื่องในโอกาสเดือนรอมฏอนประจำปีฮิจเราะห์ศักราช ๑๔๔๕ โดยมี พ.ต.ท.ชัชชัย กอศิริวลานนท์ รอง ผกก.ตชด.๔๔, 
        พ.ต.ท.เชิดชัย หน่อทอง รอง ผกก.๓ บก.สอ.บช.ตชด.ปฏิบัติราชการ รอง ผกก.ตชด.๔๔ และข้าราชการตำรวจ กก.ตชด.๔๔ 
        ร่วมพิธีสถานที่หน้าสำนักงาน กก.ตชด.๔๔  หมู่ที่๑  ต.บุดี  อ.เมือง  จว.ยะลา`,
        'www.facebook.com/bpp442/posts/pfbid02gTP6RZK2c4sXdAY1PoFDJkU2qjyZS9gTfTSfxgWe9Fv3fHubNgmqwJVS8NcsekZ5l',
        0, new Date(2024, 2, 9, 9, 0, 0, 0).toUTCString(),
        '/news/bpp4401_romdon_67.jpg', [], []
    ),
    new NewsModel(
        'องคมนตรี ปฏิบัติภารกิจเยี่ยมและให้กำลังใจจนท.',
        'ชคต.บ้านแหร ต.ธารโต อ.ธารโต จว.ยะลา',
        `เมื่อ ๑๙๑๐๔๐ เม.ย.๖๗ 
         ด้วยพระบาทสมเด็จพระเจ้าอยู่หัวทรงพระกรุณาโปรดเกล้าฯ ให้ พล.อ.ไพบูลย์ คุ้มฉายา องคมนตรี ปฏิบัติภารกิจเยี่ยมและให้กำลังใจจนท.ที่ได้รับผลกระทบจากเหตุการณ์ความไม่สงบในพื้นที่ จว.ยะลา 
         ณ. ชคต.บ้านแหร โดยมี พล.ต.ท.สำราญ นวลมา ผู้ช่วย ผบ.ตร., นายอำพล พงศ์สุวรรณ ผวจ.ยะลา, พล.ต.กรกฎ ภู่โชติ รอง ผอ.รมน.ภาค ๔ สน.(๓), พล.ต.นิติ ติณสูลานนท์ ผบ.ฉก.ยะลา, 
         พล.ต.ต.วรวิทย์ ญาณจินดา ผบก.สปพ, นายโอฬาร บิลสัน ปจ.ยะลา, นายสุริยา บุญพันธ์ นอภ.ธารโต, พ.ต.อ.จุฬาชัย แสงกุล รอง ผบก.ภจว.ยะลา, พ.ต.อ.ธนพล เยาวพักตร์ ผกก.สภ.ธารโต, 
         พ.ต.ท.ธีรศักดิ์ โพธิ์ศรีมา ผบ.ฉก.ตชด.๔๔, พ.ท.คณิน พรมมาวัน ผบ.ร.๑๕๒ พัน. ๑ พร้อมด้วย จนท.ชคต.บ้านแหร ร่วมให้การต้อนรับ`,
        'www.facebook.com/bpp442/posts/pfbid0g5FDh57TRk3oPh77YHeTpebEqbys1BSxFkQBoB3iy5jKNZF1ou25eDhZMWoMB8kul',
        0, new Date(2024, 3, 19, 0, 0, 0, 0).toUTCString(),
        '/news/SK_PI_V.jpg', [], []
    ),
    new NewsModel(
        'ร่วมพิธีเปิดหมู่บ้านนักกีฬาและเชิญธงกีฬาขึ้นสู่ยอดเสา',
        'สนามกีฬาราชนิเวศน์กรีฑาสถาน ค่ายพระรามหก  กก.๑ บก.กฝ.บช.ตชด.  อ.ชะอำ จว.เพชรบุรี',
        `เมื่อ ๒๒๐๗๓๐ เม.ย.๖๗ 
        พ.ต.อ.มานิต  นาโควงศ์ ผกก.ตชด.๔๔ ร่วมพิธีเปิดหมู่บ้านนักกีฬาและเชิญธงกีฬาขึ้นสู่ยอดเสา ในการเเข่งขันกีฬานักเรียน รร.ตชด. ครั้งที่ ๑๐ ประจำปี ๒๕๖๗ “สองเลเกมส์” 
        โดยมี นายจรูญ แก้วมุกดากูล รองอธิบดีกรมพลศึกษา กล่าวรายงาน และ พล.ต.ท.ยงเกียรติ  มนปราณีต ผู้บัญชาการตำรวจตระเวนชายแดน เป็นประธานกล่าวเปิดพิธีฯ`,
        'www.facebook.com/bpp442/posts/pfbid02Qcr8j7i46eygQSKLNqj8tYecedbUiu3cNhsob6iLf5U3S7V4zgHk2ppHWviNWBxSl',
        0, new Date(2024, 3, 22, 0, 0, 0, 0).toUTCString(),
        '/news/bpp01_child_sport.jpg', [], []
    ),
    new NewsModel(
        'อ่านสารผู้บัญชาการตำรวจตระเวนชายแดน',
        'ลานพระบรมราชานุสาวรีย์พระมหาธรรมราชาที่ ๑ (พญาลิไท) กก.ตชด.๔๔  ม.๑  ต.บุดี  อ.เมือง  จว.ยะลา',
        `เมื่อ ๐๓๐๘๕๐ พ.ค.๖๗ 
        พ.ต.อ.มานิต  นาโควงศ์  ผกก.ตชด.๔๔ ประธานในพิธีอ่านสารผู้บัญชาการตำรวจตระเวนชายแดน เนื่องในวันคล้ายวันสถาปนากองบัญชาการตำรวจตระเวนชายแดน ปีที่ ๗๑ 
        โดยมี พ.ต.ท.ธีรศักดิ์  โพธิ์ศรีมา รอง ผกก.ตชด.๔๔/ผบ.ฉก.ตชด.๔๔, พ.ต.ท.ชัชชัย กอศิริวลานนท์ รอง ผกก.ตชด.๔๔, พ.ต.ท.เชิดชัย หน่อทอง รอง ผกก.๓ บก.สอ.บช.ตชด. 
        ปฏิบัติราชการ รอง ผกก.ตชด.๔๔, สว.กก.ตชด.๔๔ (ทุกแผนกงาน), ผบ.ร้อย กก.ตชด.๔๔ (ร้อย ตชด.๔๔๑, ๔๔๒, ๔๔๘, ๔๔๙) และข้าราชการตำรวจตระเวนชายแดนที่ ๔๔ ร่วมพิธี`,
        'www.facebook.com/bpp442/posts/pfbid02YAgABCUeAXzxcUsCbTzAymwioQSXFUbWeRvanTkAuXBbuJ8LpyzsjT4avfDRNXN3l',
        0, new Date(2024, 5 - 1, 3, 8, 30, 0, 0).toUTCString(),
        '/news/bpp4401_read_info_bpp01.jpg', [], []
    ),
    new NewsModel(
        'กิจกรรมจิตอาสาพัฒนาที่ตั้งหน่วย',
        'สนามวอลเลย์บอล กก.ตชด.๔๔  ม.๑  ต.บุดี  อ.เมือง  จว.ยะลา',
        `เมื่อ ๐๓๐๙๐๐ พ.ค.๖๗ 
        พ.ต.อ.มานิต นาโควงศ์ ผกก.ตชด.๔๔ มอบหมายให้ พ.ต.ท.ชัชชัย กอศิริวลานนท์ รอง ผกก.ตชด.๔๔, พ.ต.ต.เศรษฐาแสงโพธิ์ดา สว.กก.ตชด.๔๔(จอส., กร.) 
        และข้าราชการตำรวจจิตอาสา จำนวน ๕๐ นาย เข้าร่วมกิจกรรมจิตอาสาพัฒนาที่ตั้งหน่วย เนื่องในวันคล้ายวันสถาปนากองบัญชาการตำรวจตระเวนชายแดน ปีที่ ๗๑ และร่วมรับประทานอาหารเที่ยง (ก๋วยเตี๋ยว) ณ หอประชุมอเนกประสงค์ กก.ตชด.๔๔`,
        'www.facebook.com/bpp442/posts/pfbid0ybLjf3ZbNXXFhRJF19SPghyqmGXAmFRmTmCZoB3iLZP1yw7BWnGgCwnsDRQurKDSl',
        0, new Date(2024, 5 - 1, 3, 9, 0, 0, 0).toUTCString(),
        '/news/bpp4403_jos_cl.jpg', [], []
    ),
    new NewsModel(
        'อบรมทบทวนการยิงปืนพกสั้นให้กับขรก.ตร.ฝอ.',
        'สนามยิงปืนค่ายพญาลิไท กก.ตชด.๔๔  ม.๑  ต.บุดี  อ.เมือง  จว.ยะลา',
        `เมื่อ ๐๗๐๙๐๐ พ.ค.๖๗
         พ.ต.อ.มานิต  นาโควงศ์ ผกก.ตชด.๔๔ มอบหมายให้ ชปพ.เดลต้า และ ชป.ซาไก กก.ตชด.๔๔ ทำการฝึกอบรมทบทวนการยิงปืนพกสั้นให้กับข้าราชการตำรวจฝ่ายอำนวยการ กก.ตชด.๔๔ และกองร้อย ตชด.๔๔๑ - ๔๔๒  รุ่นที่ ๑ รวมจำนวน ๔๓ นาย`,
        'www.facebook.com/bpp442/posts/pfbid02Thr8vskmvz6bEVjhYPkZ5bZH8L3ee4y2RfGnR8SehDcb1gbKX4K6RDxjimoL1UFDl',
        0, new Date(2024, 5 - 1, 7, 9, 0, 0, 0).toUTCString(),
        '/news/bpp44sup_weapon_re.jpg', [], []
    ),
    new NewsModel(
        'ทบทวนการยิงปืนพกสั้นให้กับชป.รปภ.รร.ตชด. และ ครู รร.ตชด.',
        'สนามยิงปืนค่ายพญาลิไท กก.ตชด.๔๔  ม.๑  ต.บุดี  อ.เมือง จว.ยะลา',
        `เมื่อ ๐๘๐๙๐๐-๐๘๑๖๐๐ พ.ค.๖๗ 
        พล.ต.ต.พิชญ์วุฒิ สงวนสมบัติศิริ รอง ผบช.ฯ รรท.ผบก.ตชด.ภาค ๔ เป็นประธานการเปิดฝึกอบรมโครงการฝึกอบรมเพิ่มประสิทธิภาพ ชป.รปภ.รร.ตชด. และ ครู รร.ตชด.ในสังกัด กก.ตชด.๔๔ 
        โดยมี พ.ต.ท.ธีรศักดิ์  โพธิ์ศรีมา ผบ.ฉก.ตชด.๔๔/รอง ผกก.ตชด.๔๔, พ.ต.ท.เชิดชัย หน่อทอง รอง ผกก.๓ บก.สอ.บช.ตชด.ปฏิบัติราชการ รอง ผกก.ตชด.๔๔, ผบ.ร้อย กก.ตชด.๔๔ (ร้อย ตชด.๔๔๑ - ๔๔๙),  
        สว.กก.ตชด.๔๔  (ทุกแผนกงาน), หมวดแพทย์,  ชปพ.เดลต้า, ชป.ซาไก, ชป.ซูลู มีผู้เข้ารับการฝึกอบรม จำนวน ๖๕ นาย`,
        'www.facebook.com/photo/?fbid=760044889573871&set=pcb.760045602907133',
        0, new Date(2024, 5 - 1, 8, 9, 0, 0, 0).toUTCString(),
        '/news/bpp44_teac_weaon_re.jpg', [], []
    ),
    new NewsModel(
        'กิจกรรมจิตอาสาพัฒนาคลังเก็บสิ่งของหลวง',
        'คลังเก็บสิ่งของหลวง กก.ตชด.๔๔ ต.บุดี อ.เมือง จว.ยะลา',
        `เมื่อ ๑๓๐๙๐๐ พ.ค.๖๗
        พ.ต.อ.มานิต นาโควงศ์ ผกก.ตชด.๔๔ มอบหมายให้ พ.ต.ท.เชิดชัย หน่อทอง รอง ผกก.๓ บก.สอ.บช.ตชด. ปฏิบัติราชการ รอง ผกก.ตชด.๔๔, พ.ต.ต.เศรษฐา แสงโพธิ์ดา สว.กก.ตชด.๔๔(จอส.,กร.) 
        และข้าราชการตำรวจจิตอาสา รวมจำนวน ๓๐ นาย เข้าร่วมกิจกรรมจิตอาสาพัฒนาคลังเก็บสิ่งของหลวง กก.ตชด.๔๔`,
        'www.facebook.com/bpp442/posts/pfbid02YdYNM49CYegxtrkyk28NVyyPyFK1DUKA4rRCC9uB1RKxbw5fGQUU3xJAi5Eoq2sgl',
        0, new Date(2024, 5 - 1, 13, 9, 0, 0, 0).toUTCString(),
        '/news/bpp4404_jos_clean.jpg', [], []
    ),
    new NewsModel(
        'บันทึกเทปรายการพิเศษถวายพระพรชัยมงคล',
        'ห้องส่งสถานีวิทยุโทรทัศน์แห่งประเทศไทยจังหวัดยะลา เลขที่ ๑๒ ถนนวงเวียน ๒ อ.เมือง จว.ยะลา',
        `เมื่อ ๑๔๑๑๑๕ พ.ค.๖๗
        พ.ต.อ.มานิต นาโควงศ์ ผกก.ตชด.๔๔, สว.กก.ตชด.๔๔, ผบ.ร้อย กก.ตชด.๔๔ (ร้อย ตชด.๔๔๑ - ๔๔๗) ร่วมบันทึกเทปรายการพิเศษถวายพระพรชัยมงคลเนื่องในโอกาสวันเฉลิมพระชนมพรรษา 
        สมเด็จพระนางเจ้าสุทิดา พัชรสุธาพิมลลักษณ พระบรมราชินี วันที่ ๓ มิถุนายน ๒๕๖๗`,
        'www.facebook.com/bpp442/posts/pfbid0ETZCNL6SHGUu4H48ESrpN9RzeH2gX5HHW1psemU68xoPJaNVSEF4J3nVgP6CSLsfl',
        0, new Date(2024, 5 - 1, 14, 12, 0, 0, 0).toUTCString(),
        '/news/bpp44a_rec_televi.jpg', [], []
    ),
    new NewsModel(
        'อบรมเพิ่มประสิทธิภาพชุดปฏิบัติการพิเศษ',
        'สนามยิงปืนค่ายพญาลิไท กก.ตชด.๔๔  ม.๑  ต.บุดี  อ.เมือง  จว.ยะลา',
        `เมื่อ ๑๖๑๐๐๐-๑๖๒๑๐๐ พ.ค.๖๗ 
        พ.ต.อ.มานิต  นาโควงศ์ ผกก.ตชด.๔๔ มอบหมายให้ ชปพ.เดลต้า, ชป.ซาไก และ ชป.ซูลู ดำเนินการฝึกอบรมเพิ่มประสิทธิภาพชุดปฏิบัติการพิเศษของ กก.ตชด.๔๔ มีผู้เข้ารับการฝึกอบรม จำนวน ๔๘ นาย `,
        'www.facebook.com/bpp442/posts/pfbid02VNpvJ5bkASKoqVEnrJNRWJtrgB1KyiVouXMiztHcJR8Uwh3yJEMt4RiH7Hn87jMGl',
        0, new Date(2024, 5 - 1, 16, 9, 0, 0, 0).toUTCString(),
        '/news/bpp44_spf_re_weapon.jpg', [], []
    )
];

export default news;
