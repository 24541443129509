export default class NewsModel {
    serial: string = '';
    imagePreviewUrl: string = '';
    title: string = '';
    subtitle: string = '';
    description: string = '';
    url: string = '';
    images: Array<string> = [];
    contents: Array<string> = [];
    layoutType: number = 0;
    postedDatetime: string = '';
    constructor (title: string, subtitle: string, description: string, url: string, layoutType: number = 0, postedDatetime: string, imgPreview: string, imgs: Array<string>, contents: Array<string>) {
        this.title = title;
        this.subtitle = subtitle;
        this.url = url;
        this.layoutType = layoutType;
        this.postedDatetime = postedDatetime;
        this.imagePreviewUrl = imgPreview;
        this.images = imgs;
        this.contents = contents;

        this.description = description;
        return this;
    }
}