import InOrg from "./InOrg";
import Pch from "./Pch";
import Knowledge from "./Knowledge";
export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    InOrg,
    Pch,
    Knowledge
  },
};
