import IDocument from "./IDocument";

export default class KnowledgeModel implements IDocument {
    serial: string = '';
    name: string = '';
    description: string = '';
    url: string = '';
    postedDatetime: string = '';
    constructor (serial: string, name: string, description: string, url: string, postedDatetime: string) {
        this.serial = serial;
        this.name = name;
        this.description = description;
        this.url = url;
        this.postedDatetime = postedDatetime;
        return this;
    }
}