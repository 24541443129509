import { createStore } from 'vuex'

import DocumentStore from './DocumentStore'
import NewsStore from './NewsStore'
export default createStore({
  state: {
    pageHandle: 'home'
  },
  getters: {
    getPage (state: any) {
      return state.pageHandle;
    }
  },
  mutations: {
    changePage (state: any, payload: string) {
      state.pageHandle = payload;
    }
  },
  actions: {
    changePage ({ commit }, targetPage: string = '/home') {
      commit('changePage', targetPage);
    }
  },
  modules: {
    DocumentStore,
    NewsStore
  }
})
