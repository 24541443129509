import KnowledgeModel from "@/Models/Documents/KnowledgeModel";

const documents: KnowledgeModel[] = [
    new KnowledgeModel(
        'knl001',
        'พระราชบัญญัติตำรวจแห่งชาติ พ.ศ.2565',
        '',
        'drive.google.com/file/d/1PNs3Z4xYYS7AJ7NOfDVfAmjr2mrNbvoc/view?usp=share_link',
        new Date(2022, 10, 25, 12, 0, 0, 0).toUTCString()
    ),
    new KnowledgeModel(
        'knl002',
        'ความแตกต่าง พ.ร.บ.ตำรวจฯ2547 และฉบับใหม่!',
        '',
        'drive.google.com/drive/u/9/folders/1DUvxfFJ8BRHEqxDas6KQinu0K9dNIHMJ',
        new Date(2022, 10, 25, 12, 0, 0, 0).toUTCString()
    ),
    new KnowledgeModel(
        'knl003',
        'ขั้นตอนการรับเรื่องร้องเรียนของ ก.ร.ตร.',
        '',
        'drive.google.com/file/d/102W6_KDTWsRK-Ih9wbpXAspS2Rk-rVqr/view?usp=share_link',
        new Date(2022, 11, 1, 12, 0, 0, 0).toUTCString()
    ),
    new KnowledgeModel(
        'knl004',
        'อินโฟกราฟฟิค พรบ.ตำรวจแห่งชาติ 65',
        '',
        'drive.google.com/drive/folders/1oxIlu8aUdapR55Pnt4mEiPRknE7m8HEX?usp=sharing',
        new Date(2023, 1, 8, 12, 0, 0, 0).toUTCString()
    ),
    new KnowledgeModel(
        'knl005',
        'ขั้นเงินเดือนตร.',
        '',
        'drive.google.com/file/d/1CoFYh1gqB2okc7t6ql3MS_R2WusAB6Yu/view?usp=share_link',
        new Date(2023, 1, 15, 9, 0, 0, 0).toUTCString()
    ),
    new KnowledgeModel(
        'knl006',
        'การคุ้มครองข้อมูลส่วนบุคคลและการร้องเรียนฯ',
        '',
        'drive.google.com/file/d/1V4ap5AITXf35RSSKSs9sh82fzYptGsto/view?usp=share_link',
        new Date(2023, 2, 7, 9, 0, 0, 0).toUTCString()
    ),
    new KnowledgeModel(
        'knl007',
        'องค์ประกอบ และอำนาจหน้าที่ของ ก.ต.ช., ก.ตร.',
        '',
        'www.facebook.com/bpp442/posts/pfbid07d7AF4PBzddG4BFSYnNciQyE4qYYPgjzzTS83U44Bwc5NgLY1NGirHjSFRfvWhidl',
        new Date(2023, 2, 28, 12, 0, 0, 0).toUTCString()
    ),
    new KnowledgeModel(
        'knl008',
        'พรก.มาตรการป้องกันและปราบปรามอาชญากรรมทางเทคโนโลยี',
        '',
        'drive.google.com/file/d/1EuvziFtRNYgIRCbprXjvj7RJgS5_cQJ5/view?usp=drivesdk',
        new Date(2023, 2, 28, 12, 0, 0, 0).toUTCString()
    ),
    new KnowledgeModel(
        'knl009',
        'เทคนิคการใช้งาน Microsoft Word',
        '',
        'heyzine.com/flip-book/b12ebe53fa.html',
        new Date(2024, 3 - 1, 8, 12, 0, 0, 0).toUTCString()
    ),
    new KnowledgeModel(
        'knl010',
        'การป้องกันโรคและภัยสุขภาพในฤดูร้อน',
        '',
        'heyzine.com/flip-book/b608f9da81.html',
        new Date(2024, 4 - 1, 5, 12, 0, 0, 0).toUTCString()
    )
    ,
    new KnowledgeModel(
        'knl011',
        'การประชุมทางไกลด้วย Zoom และ GG Meet',
        '',
        'www.bpp.go.th/index.php?option=com_content&view=article&layout=edit&id=5502',
        new Date(2024, 5 - 1, 15, 12, 0, 0, 0).toUTCString()
    ),
    new KnowledgeModel(
        'knl012',
        'การใช้ Thai ID กรมการปกครอง',
        '',
        'heyzine.com/flip-book/e9a2a9ee61.html#page/1',
        new Date(2024, 6 - 1, 19, 15, 0, 0, 0).toUTCString()
    )
];

export default documents;