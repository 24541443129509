import KnowledgeModel from "@/Models/Documents/KnowledgeModel";
import KnowledgeDB from '@/database/Documents/KnowledgeDB';

const documents: KnowledgeModel[] = KnowledgeDB;
const showMax = 10;
export default {
    namespaced: true,
    state: {},
    getters: {
      getKnowledgeDocs() {
        return documents;
      },
      getLastedKnowledgeDocs() {
        let total = documents.length;
        if (total < showMax) return documents.reverse();
        let lasteds: KnowledgeModel[] = [];
        for (let index = total - 1; index > total - (showMax + 1); index--){
          lasteds.push(documents[index]);      
        }
        return lasteds;
      },
    },
    mutations: {},
    actions: {},
  };