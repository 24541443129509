import InOrgModel from "@/Models/Documents/InOrgModel";
import InOrgDB from "@/database/Documents/InOrgDB";

const documents: InOrgModel[] = InOrgDB;
const showMax = 10;


export default {
  namespaced: true,
  state: {},
  getters: {
    getInOrgDocs() {
      return documents;
    },
    getLastedInOrgDocs() {
      let total = documents.length;
      if (total < showMax) return documents.reverse();
      let lasteds: InOrgModel[] = [];
      for (let index = total - 1; index > total - (showMax + 1); index--){
        lasteds.push(documents[index]);      
      }
      return lasteds;
    },
  },
  mutations: {},
  actions: {},
};
