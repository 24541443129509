import PchModel from "@/Models/Documents/PchModel";
import PchDB from '@/database/Documents/PchDB';

const documents: PchModel[] = PchDB;
const showMax = 10;
export default {
    namespaced: true,
    state: {},
    getters: {
      getPchDocs() {
        return documents;
      },
      getLastedPchDocs() {
        let total = documents.length;
        if (total < (showMax)) return documents.reverse();
        let lasteds: PchModel[] = [];
        for (let index = total - 1; index > total - (showMax + 1); index--){
          lasteds.push(documents[index]);      
        }
        return lasteds;
      },
    },
    mutations: {},
    actions: {},
  };