import NewsModel from "@/Models/News/NewsModel";
import NewsDB from '@/database/News/NewsDB';


const news: NewsModel[] = NewsDB;


const showMax = 8;
export default {
    namespaced: true,
    state: {
      
    },
    getters: {
      getNews() {
        return news;
      },
      getLastedNews() {
        let total = news.length;
        let lasteds: NewsModel[] = [];
        for (let index = total - 1; index > total - (showMax + 1); index--){
          lasteds.push(news[index]);      
        }
        return lasteds;
      },
    },
    mutations: {},
    actions: {},
  };