import InOrgModel from "@/Models/Documents/InOrgModel";
const documents: InOrgModel[] = [
    new InOrgModel(
      "0030.541/195",
      "อนุมัติหลักการขอรับแต่งตั้งโยกย้าย บก.ตชด.ภาค4",
      "",
      "drive.google.com/file/d/15XFvsbBaIxGmXKI67aVcoq0F-lZLDEJT/view?fbclid=IwAR3Q0732CctsB4sQpqJwC83NJ6tUizOKZxGy-PnKIlaCNKNSDJZ7yfM_mvg",
      new Date(2023, 0, 31, 18, 0, 0, 0).toUTCString(),
      1
    ),
    new InOrgModel(
      "0030.541/315",
      "สำเนาประชมฝอ.ประจำสัปดาห์ 31/1/66",
      "",
      "drive.google.com/file/d/1oo05_s567mVyTGbBPnV-4WZZl6HW2JA6/view?fbclid=IwAR2xwutbtJjDP96qsDRornNBFGwdsukZC3PdKygQMfka2xOzEUWY6o6dyEg",
      new Date(2023, 1, 3, 18, 0, 0, 0).toUTCString(),
      1
      
    ),
    new InOrgModel(
      "0030.543/231",
      "ผลการสำรวจความเห็นประชาชนตามแผนป้องกันยาเสพติด",
      "",
      "drive.google.com/file/d/1Apb7Z8Sl-Qbc19ekJAWDaFblVKs26KVj",
      new Date(2023, 1, 6, 18, 0, 0, 0).toUTCString(),
      3
    ),
    new InOrgModel(
      "0030.543/262",
      "เอกสารขอรับเงินค่าตอบเแทนฯและปราบปรามยาเสพติด",
      "",
      "drive.google.com/file/d/1SdVcMjWqpCuKlXbdy0fFHYd1HjpkZaPh",
      new Date(2023, 1, 9, 18, 0, 0, 0).toUTCString(),
      3
    ),
    new InOrgModel(
      "0030.541/254",
      "ขอรับทุนการศึกษาบุตรจากสมาคมแม่บ้านตร.",
      "",
      "drive.google.com/file/d/1NwITO2HJyQCjCz99Tsq6QUHJkeBpOhQP",
      new Date(2023, 1, 9, 18, 0, 0, 0).toUTCString(),
      1
    ),
    new InOrgModel(
      "0030.541/283",
      "การทำหนังสือประสงค์ขอกู้เงินผ่านผู้บังคับบัญชาตามลำดับ",
      "",
      "drive.google.com/file/d/1oo05_s567mVyTGbBPnV-4WZZl6HW2JA6/view?fbclid=IwAR2xwutbtJjDP96qsDRornNBFGwdsukZC3PdKygQMfka2xOzEUWY6o6dyEg",
      new Date(2023, 1, 13, 18, 0, 0, 0).toUTCString(),
      1
    ),
    new InOrgModel(
      "0030.161/1416",
      "เจตนารมณ์ในการป้องกันการทุจริตของกองบัญชาการตำรวจตระเวนชายแดน",
      "",
      "drive.google.com/file/d/1WNw_dzRvldUFu43EUOFPhvvgKi7shVM4/view?usp=sharing",
      new Date(2023, 2, 14, 9, 0, 0, 0).toUTCString(),
      1
    ),
    new InOrgModel(
      "0030.161/1416-2",
      "เจตจำนงสุจริตในการบริหารงานกองบัญชาการตำรวจตระเวนชายแดน",
      "",
      "drive.google.com/file/d/1Os2c2iJVp2QKPEZ6nhkCCkIJowAPIGcg/view?usp=share_link",
      new Date(2023, 2, 14, 9, 0, 0, 0).toUTCString(),
      1
    ),
    new InOrgModel(
      "0030.164/1422",
      "รับฟังความคิดเห็นประกอบการเสนอร่างกฏกระทรวง",
      "ออกตามความในพระราชบัญญัติอาวุธปืน เครื่องกระสุนปืน วัตถุระเบิด ดอกไม้เพลิง และสิ่งเทียมอาวุูธปืน พ.ศ. 2490",
      "www.law.go.th/listeningDetail?survey_id=MTU3NURHQV9MQVdfRlJPTlRFTkQ&fbclid=IwAR3UkcPBU9cWpqr4LcUyyt2WUVXoxHTspv1XsG9ur52cX5rsdV69Ulmwmtc",
      new Date(2023, 2, 14, 12, 0, 0, 0).toUTCString(),
      0
    ),
    new InOrgModel(
      "0030.112/1622",
      "ประกาศลำดับอาวุโส บช.ตชด.66",
      "ออกตามความในพระราชบัญญัติอาวุธปืน เครื่องกระสุนปืน วัตถุระเบิด ดอกไม้เพลิง และสิ่งเทียมอาวุูธปืน พ.ศ. 2490",
      "drive.google.com/file/d/1PuuoyimwmfAQXszl5dS7Ed7JrqICuw5_/view?usp=share_link",
      new Date(2023, 2, 21, 10, 0, 0, 0).toUTCString(),
      0
    ),
    new InOrgModel(
      "พิเศษ",
      "ช่องทางอิเล็กทรอนิกส์สำหรับติดต่อสตช.",
      "",
      "www.bpp.go.th/documents/2566/25660707_03.pdf",
      new Date(2023, 6, 13, 10, 0, 0, 0).toUTCString(),
      0
    ),
    new InOrgModel(
      "พิเศษ",
      "เว็บไซด์กองทุนเพื่อการ สืบสวน สอบสวนฯ ทางอาญา",
      "",
      "criminalfund.police.go.th",
      new Date(2023, 8, 4, 10, 0, 0, 0).toUTCString(),
      0
    ),
   
    new InOrgModel(
      "พิเศษ",
      "ประกาศรายชื่อผู้มีสิทธิ์สอบแข่งขันเพื่อทำหน้าที่ครูฯ",
      "",
      "drive.google.com/file/d/1IPpQ3iwb0TqtR2_NoYRmcrUvyyE6sXh7/view?usp=sharing",
      new Date(2024, 2, 5, 9, 0, 0, 0).toUTCString(),
      2
    ),
    new InOrgModel(
      "พิเศษ",
      "ประกาศรายชื่อผู้สอบได้ เพื่อทำหน้าที่ครูผู้ช่วยสอน",
      "",
      "drive.google.com/file/d/1WQl6eeQsoTI5OQdV_GU5ZHqu7nQOrG7C/view?usp=sharing",
      new Date(2024, 2, 12, 9, 0, 0, 0).toUTCString(),
      2
    ),
    new InOrgModel(
      "พิเศษ",
      "รับสมัครบุคคลเพื่อทำหน้าที่วิทยากรผู้สอนภาษาไทย รร.ตชด.ในสังกัดฯ",
      "",
      "drive.google.com/file/d/1S2Bg92xn4K0mhGruaf6b_LpMpf6Z6SMy/view?usp=drive_link",
      new Date(2024, 5, 20, 15, 0, 0, 0).toUTCString(),
      2
    ),
    new InOrgModel(
      "พิเศษ",
      "ประกาศราขื่อผู้มีสิทธิสอบเพื่อทำหน้าที่วิทยากรผู้สอนภาษาไทย รร.ตชด.ในสังกัดฯ",
      "",
      "drive.google.com/file/d/1WAaZxOqrqAY5w8jkVLs1KwUqf7HZT-n9/view?usp=sharing",
      new Date(2024, 7 - 1, 11, 15, 0, 0, 0).toUTCString(),
      2
    ),
    new InOrgModel(
      "พิเศษ",
      "ประกาศรายชื่อรอบสุดท้ายผู้ทำหน้าที่วิทยากรผู้สอนภาษาไทย รร.ตชด.ในสังกัดฯ",
      "",
      "drive.google.com/file/d/1-XNMcX8M3S-Ti5U2b02ybbp6CZjkt2ru/view?usp=sharing",
      new Date(2024, 7 - 1, 18, 15, 0, 0, 0).toUTCString(),
      2
    ),
  ];

  export default documents;