import IDocument from "./IDocument";

export default class InOrgModel implements IDocument {
    serial: string = '';
    name: string = '';
    description: string = '';
    url: string = '';
    postedDatetime: string = '';

    departmentId: Number = -1;
    constructor (serial: string, name: string, description: string, url: string, postedDatetime: string, departmentId: Number) {
        this.serial = serial;
        this.name = name;
        this.description = description;
        this.url = url;
        this.postedDatetime = postedDatetime;

        this.departmentId = departmentId;
        return this;
    }
}